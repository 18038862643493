import { keyBy } from "lodash";
import { getCurrentSocket } from "../socketManager";
import { TURBINE_LAST_UPDATE } from "../constants";
import { LIVE_PARAMS_UPDATE } from "../constants";

let subscribed = [];

export const updateLiveParameters = (turbine, lastTimestamp, valuesObject) => ({
  type: LIVE_PARAMS_UPDATE,
  payload: {
    windTurbineId: turbine.id,
    data: {
      ...valuesObject,
      timestamp: lastTimestamp,
    },
  },
});

export const turbineLastUpdate = (turbine, timestamp) => ({
  type: TURBINE_LAST_UPDATE,
  payload: {
    windTurbineId: turbine.id,
    data: timestamp,
  },
});

export const subscribeToLiveParams =
  (windTurbines, fromLocation = "dashboard") =>
  async (dispatch) => {
    let lastTimestamp;

    const handleSocketMessage = (turbine, data) => {
      const shouldUpdate = data && data.timestamp !== lastTimestamp;

      if (shouldUpdate) {
        lastTimestamp = data.timestamp;

        const values = data.values.map((v) => ({
          ...v,
          id: v.id.substring(v.id.lastIndexOf(".") + 1).toLowerCase(),
        }));

        const valuesObj = keyBy(values, "id");

        dispatch(updateLiveParameters(turbine, lastTimestamp, valuesObj));
        dispatch(turbineLastUpdate(turbine, lastTimestamp));
      }
    };

    const s = await getCurrentSocket();

    const fromElem = windTurbines[windTurbines.length - 1];

    let from = "";
    if (typeof fromElem !== "undefined" && fromElem.hasOwnProperty("from")) {
      from = fromElem.from;
      if (!fromElem.hasOwnProperty("id")) {
        windTurbines.pop();
      }
    }

    if (from.length == 0 && fromLocation) {
      from = fromLocation;
    }

    windTurbines.forEach((turbine) => {
      if (subscribed.indexOf(turbine.identifier) < 0) {
        s.emit("connect", turbine.identifier);
        s.emit("subscribe", turbine, "", from);
        s.on(`data-${turbine.identifier}`, (data) =>
          handleSocketMessage(turbine, data)
        );
        subscribed.push(turbine.identifier);
      }
    });
  };

export const unsubscribeFromLiveParams = (windTurbines) => async (dispatch) => {
  const s = await getCurrentSocket();

  Object.keys(windTurbines).forEach((turbineId) => {
    const turbine = windTurbines[turbineId];

    let from = "";
    if (turbine.hasOwnProperty("from")) {
      from = turbine.from;
      delete turbine.from;
    }
    if (subscribed.indexOf(turbine.identifier) >= 0) {
      s.off(`data-${turbine.identifier}`);
      s.emit("unsubscribe", turbine.identifier, "", from);
      s.emit("disconnect", turbine.identifier);
      subscribed = subscribed.filter((t) => t !== turbine.identifier);
      console.log("---DISCONNECT FROM ", turbine.identifier, from, subscribed);
    }
  });
};
