import React, { useContext, useState } from "react";
import styled, { ThemeContext } from "styled-components";
import moment from "moment";
import DatePicker from "react-datepicker";
import DataGraph from "containers/history/DataGraph";
import PeriodButtons from "./PeriodButtons";
import { CHART_TYPE } from "../../constants";
import { Button, Icon } from "components/shared";
import { getAllMonitoringParameters } from "selectors/turbineParams";

import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";

const Wrapper = styled.div``;

const GraphWrapper = styled.div`
  padding: 24px;

  //size taken from mobile semantic ui threshold
  @media (max-width: 767px) {
    padding: 0.5rem;
  }
`;

const DatePickerWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background-color: ${(props) => props.theme.controlsBackgroundColor};
  border-radius: 8px;

  .menu {
    margin: 0rem;
  }
`;

const DateButton = styled.button`
  border: 0;
  background-color: transparent;
  color: ${(props) => props.theme.textDark};
  word-break: break-all;

  &:hover {
    cursor: pointer;
  }
`;

const DateButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ArrowButton = styled.button`
  border: 0;
  outline: none;
  background-color: transparent;
  padding: 0;

  &:hover {
    cursor: pointer;
  }
`;

const TodayButton = styled(Button)`
  &&& {
    color: ${(props) => props.theme.textDark};
    border: 2px solid ${(props) => props.theme.accentColor};
    border-radius: 5px;
    box-shadow: 0;
  }
`;

const StyledIcon = styled(Icon)`
  &&& {
    color: ${(props) => props.color || props.theme.accentColor};
  }
`;

const History = ({ windTurbine, monitoringParams }) => {
  const [datePickerVisible, setDatePickerVisible] = useState(false);
  const [date, setDate] = useState(new Date());
  const themeContext = useContext(ThemeContext);
  const [chartType, setChartType] = useState("Hourly");

  const [firstMonitoredParameter, setFirstMonitoredParameter] = useState(
    monitoringParams.find((p) => p.dbname === "power")
  );

  const [secondMonitoredParameter, setSecondMonitoedParameter] = useState(
    monitoringParams.find((p) => p.dbname === "wind")
  );

  const onFirstParameterChange = (ev, newParam) => {
    let firstParam = monitoringParams.find(
      (p) => p.name === newParam.name || p.name === newParam.text
    );

    if (!firstParam) {
      firstParam = monitoringParams.find((p) => p.name == newParam.name);
    }

    setFirstMonitoredParameter(firstParam);
  };

  const onSecondParameterChange = (ev, newParam) => {
    let secondParam = monitoringParams.find(
      (p) => p.name === newParam.name || p.name === newParam.text
    );

    if (!secondParam) {
      secondParam = monitoringParams.find((p) => p.name == newParam.name);
    }

    setSecondMonitoedParameter(secondParam);
  };

  const handleChange = (d) => {
    setDate(d);
    toggleCalendar();
  };

  const toggleCalendar = (e) => {
    e && e.preventDefault();
    setDatePickerVisible(!datePickerVisible);
  };

  let unit;
  let dateFormat;
  switch (chartType) {
    case CHART_TYPE.Hourly:
      unit = "days";
      dateFormat = "DD MMM YYYY";
      break;
    case CHART_TYPE.Daily:
      unit = "months";
      dateFormat = "MMMM YYYY";
      break;
    case CHART_TYPE.Monthly:
      unit = "years";
      dateFormat = "YYYY";
      break;
    default:
      unit = "days";
      dateFormat = "DD MMM YYYY";
      break;
  }

  const onPrevious = () => {
    setDate(moment(date).add(-1, unit).toDate());
  };

  const onNext = () => {
    setDate(moment(date).add(1, unit).toDate());
  };

  const onToday = () => {
    setDate(new Date());
  };

  const onButtonClick = (item) => {
    setChartType(item);
  };

  const isBeforeToday = moment().isAfter(moment(date), "day");

  return (
    <Wrapper>
      <GraphWrapper>
        <DataGraph
          key={`${date} ${chartType}`}
          chartType={chartType}
          date={moment(date).format(dateFormat)}
          windTurbine={windTurbine}
          firstMonitoredParameter={firstMonitoredParameter}
          secondMonitoredParameter={secondMonitoredParameter}
          onfirstParameterChange={onFirstParameterChange}
          onsecondParameterChange={onSecondParameterChange}
        />
      </GraphWrapper>
      <DatePickerWrapper>
        <PeriodButtons
          className="test"
          buttons={[CHART_TYPE.Hourly, CHART_TYPE.Daily, CHART_TYPE.Monthly]}
          onButtonClick={onButtonClick}
        />
        <DateButtonsWrapper>
          <ArrowButton onClick={onPrevious}>
            <StyledIcon name="chevron left" />
          </ArrowButton>
          <div>
            <DateButton onClick={toggleCalendar}>
              {moment(date).format(dateFormat)}
            </DateButton>
            {datePickerVisible && (
              <DatePicker
                selected={date}
                maxDate={new Date()}
                onChange={handleChange}
                withPortal
                inline
              />
            )}
          </div>
          <ArrowButton onClick={isBeforeToday ? onNext : null}>
            <StyledIcon
              name="chevron right"
              color={isBeforeToday ? themeContext.accentColor : "grey"}
            />
          </ArrowButton>
        </DateButtonsWrapper>
        <TodayButton onClick={onToday} secondary>
          Today
        </TodayButton>
      </DatePickerWrapper>
    </Wrapper>
  );
};

const mapStateToProps = (state, ownProps) => ({
  monitoringParams: getAllMonitoringParameters(state, ownProps.windTurbine.id),
});

export default connect(mapStateToProps)(History);
