import React, { useState } from 'react';
import { Form } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

import AuthForm from './AuthForm';
import {
  Button,
} from 'components/shared/index';
import InputField from '../InputField';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const FormWrapper = styled.div`
  width: 80%;
  margin: 40px 0;
`;

const styles = {
  infoLabel: {
    fontWeight: '500',
    backgroundcolor: 'transparent',
    marginTop: -12
  },
  formElement: {
    marginBottom: 30,
  }
};

const ResetPasswordForm = ({ onResetPassword, isLoading }) => {
  const [password, setPassword] = useState('');
  const [retypePassword, setRetypePassword] = useState('');

  const onPasswordChange = (ev, { value }) => {
    setPassword(value);
  };

  const onRetypePasswordChange = (ev, { value }) => {
    setRetypePassword(value);
  };

  const onSubmit = () => {
    onResetPassword(password);
  };

  return (
    <AuthForm>
      <FormWrapper onSubmit={onSubmit}>
        <Form style={{ display: 'flex', flexDirection: 'column' }}>
          <Form.Field>
            <InputField
              value={password}
              type="password"
              size="huge"
              secureText
              style={styles.formElement}
              onChange={onPasswordChange}
              placeholder="New Password"
            />
          </Form.Field>
          <Form.Field>
            <InputField
              value={retypePassword}
              type="password"
              size="huge"
              secureText
              style={styles.formElement}
              onChange={onRetypePasswordChange}
              placeholder="Retype New Password"
            />
          </Form.Field>
          <Button
            type="submit"
            size="huge"
            style={styles.formElement}
            onSubmit={onSubmit}
            loading={isLoading}
            disabled={isLoading}
          >
            Update Password
          </Button>
        </Form>
      </FormWrapper>
    </AuthForm>
  );
};

ResetPasswordForm.propTypes = {
  onResetPassword: PropTypes.func,
  isLoading: PropTypes.bool,
};

ResetPasswordForm.defaultProps = {
  onResetPassword: () => {},
  isLoading: false,
};

export default withRouter(ResetPasswordForm);
