import React from "react";
import styled from "styled-components";
import { AppHeader, SignIn, AppFooter } from "components";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import { ScreenSizes } from "components/shared";

const Wrapper = styled.div`
  margin: auto;
  max-width: 1200px;
  border-radius: 20px;
  box-shadow: 1px 1px 4px 2px rgba(0, 0, 0, 0.1);
  background-color: ${(props) => props.theme.backgroundcolor};
  margin-bottom: 2rem;
  margin-top: 2rem;

  ${ScreenSizes.lessThan("mobile")`
  margin-top: 0rem;
  margin-bottom: 0rem; 
  border-radius: 0rem;
  `}
`;

const PrivateRoute = ({ component: Component, authenticated, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      authenticated ? (
        <>
          <Wrapper>
            <AppHeader />
            <Component {...props} />
            <AppFooter />
          </Wrapper>
        </>
      ) : (
        <SignIn {...props} />
      )
    }
  />
);

const mapStateToProps = (state) => ({
  authenticated: state.session.authenticated,
});

export default connect(mapStateToProps)(PrivateRoute);
