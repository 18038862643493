import React, { useEffect } from "react";
import { parse } from "content-range";
import { connect } from "react-redux";
import useAxios from "axios-hooks";
import { keys } from "loadsh";

import {
  setViewMode,
  setPageNumber,
  setTotalPageNumber,
  loadTurbines,
} from "actions/dashboard";

import { loadWindTurbineParams } from "actions/details";

import {
  subscribeToLiveParams,
  unsubscribeFromLiveParams,
} from "actions/liveParams";

import { TURBINE_VIEW_MODES } from "../../constants";
import { ITEMS_PER_PAGE } from "../../constants";

import DashboardComponent from "components/dashboard";

const Dashboard = (props) => {
  const [{ loading, data, _, response }, getWindTurbines] = useAxios(
    {
      url: "/windTurbines",
      params: {
        skipUsers: true,
      },
    },
    {
      manual: true,
    }
  );

  const {
    viewMode,
    pageNumber,
    windTurbines,
    subscribeToLiveParams,
    unsubscribeFromLiveParams,
  } = props;

  const windmillsData = data ? data : [];
  const val = React.useRef();

  React.useEffect(() => {
    val.current = props;
  }, [props, windTurbines]);

  useEffect(() => {
    getWindTurbines({
      params: {
        limit: viewMode == TURBINE_VIEW_MODES.GRID ? ITEMS_PER_PAGE : -1,
        offset:
          viewMode == TURBINE_VIEW_MODES.GRID
            ? (pageNumber - 1) * ITEMS_PER_PAGE
            : 0,
      },
    });
  }, [viewMode, pageNumber]);

  useEffect(() => {
    if (loading) return;
    if (!data) return;

    props.dispatch(loadTurbines(data));

    verifyResponse(response);

    verifyRange(response.headers["content-range"]);

    data.forEach((t) => {
      props.loadWindTurbineParams(t.id, t.type);
    });
  }, [data]);

  const verifyRange = (rangeHeader) => {
    const contentRange = parse(rangeHeader);

    if (contentRange && contentRange.size) {
      props.dispatch(setTotalPageNumber(contentRange));
    }
  };

  const verifyResponse = (response) => {
    if (!response) return;
    if (!response.headers["content-range"]) return;
  };

  useEffect(() => {
    return () => {
      let currentWts = val.current.windTurbines;
      currentWts.from = "dashboard";
      unsubscribeFromLiveParams(currentWts);
    };
  }, [pageNumber]); // eslint-disable-line

  useEffect(() => {
    let turbinesArray = keys(windTurbines)
      .map((key) => {
        if (key != "from") {
          let wt = windTurbines[key];
          wt.from = "dashboard";
          return wt;
        }
      })
      .filter((turbine) => {
        return turbine !== undefined;
      });

    subscribeToLiveParams(turbinesArray);
  }, [subscribeToLiveParams, windTurbines]);

  return (
    <DashboardComponent data={windmillsData} isLoading={loading} {...props} />
  );
};

const mapStateToProps = (state) => ({
  viewMode: state.dashboard.viewMode,
  pageNumber: state.dashboard.pageNumber,
  totalPageNumber: state.dashboard.totalPageNumber,
  windTurbines: state.windTurbines,
});

const mapDispatchToProps = (dispatch) => ({
  setViewMode: (viewMode) => dispatch(setViewMode(viewMode)),
  setPageNumber: (pageNumber) => dispatch(setPageNumber(pageNumber)),
  loadWindTurbineParams: (windTurbineId, type) =>
    dispatch(loadWindTurbineParams(windTurbineId, type)),
  subscribeToLiveParams: (windTurbines) =>
    dispatch(subscribeToLiveParams(windTurbines)),
  unsubscribeFromLiveParams: (windTurbines) =>
    dispatch(unsubscribeFromLiveParams(windTurbines)),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
