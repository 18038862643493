import { LIVE_PARAMS_UPDATE, PARAM_UPDATE } from "../constants";

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case LIVE_PARAMS_UPDATE: {
      const { windTurbineId } = action.payload;
      return {
        ...state,
        [windTurbineId]: {
          ...state[windTurbineId],
          ...action.payload.data,
        },
      };
    }
    case PARAM_UPDATE:
      return {
        ...state,
        [action.payload.windTurbineId]: {
          ...state[action.payload.windTurbineId],
          [action.payload.paramId]: {
            id: action.payload.paramId,
            v: action.payload.newValue,
          },
        },
      };

    default:
      return state;
  }
};
