import { connect } from 'react-redux';
import { AccountHeader } from 'components/shared';
import { logout } from 'actions/auth';

const mapStateToProps = state => ({
  user: state.session.user,
});

export default connect(mapStateToProps, {
  logout,
})(AccountHeader);
