import React, { useContext, useState } from "react";
import { Menu } from "components/shared";
import { ThemeContext } from "styled-components";

const PeriodButtons = ({ buttons, onButtonClick }) => {
  const themeContext = useContext(ThemeContext);
  const [activeItem, setActiveItem] = useState(buttons[0]);

  const onMenuItemClick = (item) => {
    setActiveItem(item);
    onButtonClick(item);
  };

  return (
    <Menu
      backgroundcolor={themeContext.controlsBackgroundColor}
      links={buttons.map((b) => ({ label: b }))}
      controlled
      activeLink={activeItem}
      onItemClick={onMenuItemClick}
      asAccordionMobile={false}
      withoutNavigation
      elementWidth={25}
      elementHeight={5}
      autoFontAdjustment={true}
      layout="block"
    />
  );
};

PeriodButtons.propTypes = {};

PeriodButtons.defaultProps = {};

export default PeriodButtons;
