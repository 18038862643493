import React, { useContext, useEffect, useState } from "react";
import styled, { ThemeContext } from "styled-components";
import { ScreenSizes } from "components/shared";
import RPM from "components/dashboard/RPM";
import LiveDataGraph from "containers/live/LiveDataGraph";
import Temperature from "containers/weather/Temperature";
import { Grid, Icon } from "semantic-ui-react";
import { FaWind } from "react-icons/fa";
import { GiElectric } from "react-icons/gi";
import { Progress } from "components/shared";
import { shadeColor } from "utils/colors";
import { WindTurbineMode } from "components/shared";
import { Text } from "components/shared";

const ErrorOverlay = styled.div`
  position: absolute;
  border-radius: 3px;
  opacity: 0.8;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  background-color: ${(props) =>
    props.displayerror ? "#404B4A" : "transparent"};
  height: 5rem;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;

  ${ScreenSizes.greaterThan("mobile")`
  display: ${(props) =>
    props.displayerror && props.fulldetails ? "none" : "flex"} 
  `}
`;

const Error = styled((props) => <Text {...props} />)`
  color: red;
  font-weight: 800;
  z-index: 1;
`;

const GridColumn = styled((props) => <Grid.Column {...props} />)`
  &&& {
    ${ScreenSizes.lessThan("mobile")`
  padding: 0rem !important;
  `}
  }
`;

const GeneralGrid = styled((props) => <Grid {...props} />)`
  &&& {
    ${ScreenSizes.lessThan("mobile")`
  `}
  }
`;

const DATA_DELAY = 18000;

const DashboardCardContent = ({
  windTurbine,
  fullDetails,
  liveProps,
  turbineState,
}) => {
  const { rpm_rotor, wind, power, mode, err } = liveProps;
  const themeContext = useContext(ThemeContext);
  const [displayError, updateDisplayError] = useState(false);

  useEffect(() => {
    if (!!err && err !== 0) {
      updateDisplayError(true);
    } else {
      updateDisplayError(false);
    }
  }, [err]);

  const renderGraph = (height) => (
    <LiveDataGraph windTurbine={windTurbine} height={height} />
  );

  const getSignal = (turbineState) => {
    return turbineState && turbineState.lastUpdate
      ? (turbineState.lastUpdate - Date.now() + DATA_DELAY) / 1000
      : 0;
  };

  const renderSignal = (turbineState) => {
    return getSignal(turbineState) > 0 && fullDetails ? (
      <Icon color="grey" name="signal"></Icon>
    ) : null;
  };

  const renderRotationTemperatureAndMode = () => {
    return (
      <Grid.Row
        style={{
          alignItems: "center",
          justifyContent: "space-around",
          gap: "1rem",
          padding: "0rem",
        }}
      >
        {renderSignal(turbineState)}
        <RPM value={rpm_rotor} />
        <Temperature
          coordinates={{
            latitude: windTurbine.latitude,
            longitude: windTurbine.longitude,
          }}
        />
        <WindTurbineMode mode={mode === 0 ? "M" : "A"} />
      </Grid.Row>
    );
  };

  const renderCircularProgressMeter = ({
    type,
    value,
    maxValue,
    strokeStats,
    icon,
    unit,
  }) => {
    return (
      <Progress
        type={type}
        strokeColor={strokeStats}
        maxValue={maxValue}
        value={value}
        strokeWidth={9}
        icon={icon}
        unit={unit}
        trailColor={themeContext.circleBackground}
        fulldetails={fullDetails}
        err={err}
      />
    );
  };

  const firstProgressMeter = {
    type: "dashboard",
    value: power,
    maxValue: windTurbine.maxPower || 60,
    strokeStats: {
      "0%": shadeColor(themeContext.primaryColor, 10),
      "100%": themeContext.primaryColor,
    },
    icon: <GiElectric color={themeContext.primaryColor} />,
    unit: "kW",
  };

  const secondProgressMeter = {
    type: "dashboard",
    value: wind,
    maxValue: 60,
    strokeStats: {
      "0%": shadeColor(themeContext.secondaryColor, 90),
      "100%": themeContext.secondaryColor,
    },
    icon: <FaWind color={themeContext.secondaryColor} />,
    unit: "m/s",
  };

  const renderVisualMeters = () => {
    return (
      <Grid padded="horizontally" columns={2}>
        <Grid.Row
          verticalAlign="middle"
          style={{ maxWidth: "35rem", margin: "auto" }}
        >
          <GridColumn>
            {renderCircularProgressMeter(firstProgressMeter)}
          </GridColumn>
          <GridColumn>
            {renderCircularProgressMeter(secondProgressMeter)}
          </GridColumn>
          {
            <ErrorOverlay
              fulldetails={fullDetails}
              displayerror={displayError}
              justifyContent={fullDetails ? "flex-start" : "center"}
              paddingLeft={fullDetails ? 200 : 16}
            >
              <Error size={20}>{err}</Error>
            </ErrorOverlay>
          }
        </Grid.Row>
        {renderRotationTemperatureAndMode()}
      </Grid>
    );
  };

  return (
    <GeneralGrid
      stackable
      fulldetails={fullDetails ? 1 : 0}
      columns={fullDetails ? 2 : 1}
    >
      {renderVisualMeters()}
      {fullDetails && (
        <Grid.Column verticalAlign="middle">
          {fullDetails && renderGraph()}
        </Grid.Column>
      )}
    </GeneralGrid>
  );
};

DashboardCardContent.propTypes = {};

DashboardCardContent.defaultProps = {};

export default React.memo(DashboardCardContent);
