import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {ScreenSizes} from 'components/shared'
import {FiArrowRightCircle} from 'react-icons/fi';

import {Text} from 'components/shared';

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  ${ScreenSizes.lessThan('tablet')`
  padding: 0 0.5rem 0 0;
  `}
`;

const TemperatureText = styled(props => <Text {...props} />)`
  color: ${props => props.theme.textDark};
  font-weight: 400;
`;

const TemperatureDegrees = styled(TemperatureText)`
  color: ${props => props.theme.textMedium};
  font-weight: 300;
`;

const Temperature = ({value, hideIcon}) => {
  return (
    <Wrapper>
      {!hideIcon && <FiArrowRightCircle color="grey" size="1.5em" />}
      {value ? (
        [<TemperatureText key='value' size={23}>{value}</TemperatureText>,
        <TemperatureDegrees key='degrees' size={21}>°C</TemperatureDegrees>]
      ) : <TemperatureText style={{marginRight: 24}} key='value' size={17}>N/A</TemperatureText>}
    </Wrapper>
  );
};

Temperature.propTypes = {
  hideIcon: PropTypes.bool,
};

Temperature.defaultProps = {
  hideIcon: false,
};

export default Temperature;
