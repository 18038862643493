import React from 'react';
import { toast } from 'react-toastify';
import useAxios from 'axios-hooks';

import { ResetPasswordForm } from 'components/auth/forms';

const ResetPasswordContainer = ({ history, token }) => {
  const onResetPasswordError = (error) => {
    toast.error(error.response.data.error);
  };

  const onResetPasswordSuccess = (data) => {
    toast.success('Password reset successfully');
    history.push('/');
  };

  const [{ loading, data, error }, resetPassword] = useAxios({
    url: "/auth/changePassword",
    method: "POST"
  }, {
    manual: true
  });

  if (data) {
    onResetPasswordSuccess(data);
  }

  if (error) {
    onResetPasswordError(error)
  }

  const onSubmitResetPassword = (password) => {
    resetPassword({ data: { token, password } });
  };

  return (
    <ResetPasswordForm
      onResetPassword={onSubmitResetPassword}
      isLoading={loading}
    />
  )
};

export default ResetPasswordContainer;
