import React from 'react';
import styled from 'styled-components';

import HelpAndFAQ from 'components/helpAndFAQ';
import { ThemeToggle } from 'components';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(min-content, min-content) 1fr minmax(min-content, min-content);
  grid-column-gap: 0.5rem;
  align-items: center;
  justify-content: center;
  padding: 1rem;
`;

const Copyright = styled.span`
  color: ${props => props.theme.textMediumLight};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AppFooter = () => {
  return (
    <Wrapper>
      <ThemeToggle />
      <Copyright>Copyright 2019</Copyright>
      <HelpAndFAQ />
    </Wrapper>
  );
};

AppFooter.propTypes = {};

AppFooter.defaultProps = {};

export default AppFooter;
