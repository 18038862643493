class LoginValidation {
  #validateEmail(emailValue) {
    let emailpattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return String(emailValue) && emailpattern.test(emailValue);
  }

  #validatePassword(passwordValue) {
    //Minimum required length is set to 3 chars with no other validation. Validation can be added and updated in this method as requested;
    return String(passwordValue).length >= 3;
  }

  validateCredentials(email, password) {
    return this.#validateEmail(email) && this.#validatePassword(password);
  }
}

export default LoginValidation;
