import { SET_THEME } from "../constants";
import { THEME } from "../constants";
import { SET_IS_IDLE } from "../constants";

export const toggleTheme = () => (dispatch, getState) => {
  const currentTheme = getState().settings.theme;
  let newTheme = "dark";
  if (currentTheme === "dark") {
    newTheme = "light";
  }

  localStorage.setItem(THEME, newTheme);
  dispatch(setTheme(newTheme));
};

export const setTheme = (theme) => ({
  type: SET_THEME,
  payload: { theme },
});

export const setIsIdle = (isIdle) => ({
  type: SET_IS_IDLE,
  payload: { isIdle },
});
