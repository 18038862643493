import React, { useEffect, useContext, useState, useRef } from "react";
import styled, { ThemeContext } from "styled-components";
import { Loader, Sticky, Button, Icon } from "semantic-ui-react";
import { connect } from "react-redux";

import CardHeader from "containers/dashboard/CardHeader";
import {
  Card,
  CardContent,
  ContentContainer,
  EmptyView,
  Menu,
} from "components/shared";
import LiveContent from "components/live";
import HistoryContent from "components/history";
import AdvancedContent from "components/advanced";
import { loadWindTurbineParams } from "actions/details";
import {
  subscribeToLiveParams,
  unsubscribeFromLiveParams,
} from "actions/liveParams";

import WindTurbinesGrid from "components/dashboard/WindTurbinesGrid";
import WindTurbinesMap from "components/dashboard/WindTurbinesMap";
import WindTurbinesList from "components/dashboard/WindTurbinesList";

import { LOAD_WIND_TURBINE } from "../../constants";
import { TURBINE_VIEW_MODES } from "../../constants";

import useAxios from "axios-hooks";
import ServiceLogs from "containers/advanced/ServiceLogs";
import { setViewMode } from "actions/dashboard";
import { useHistory } from "react-router";

const ITEMS = [
  {
    label: "LIVE",
  },
  {
    label: "HISTORY",
  },
  {
    label: "ADVANCED",
  },
  {
    label: "SERVICE LOGS",
  },
];

const ViewModesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

const Details = ({
  match,
  loadWindTurbineParams,
  dispatch,
  windTurbines,
  subscribeToLiveParams,
  unsubscribeFromLiveParams,
  viewMode,
  setViewMode,
  ...props
}) => {
  const contextRef = useRef();
  const history = useHistory();
  const themeContext = useContext(ThemeContext);

  const windTurbineId = match.params.id;
  const [activeItem, setActiveItem] = useState(ITEMS[0].label);
  const [windTurbine, setWindTurbine] = useState(null);

  const VIEW_MODE_BUTTONS = Object.values(TURBINE_VIEW_MODES);

  //TODO: replace current view mode buttons with component which will be imported (logic will be moved to separate component)

  const renderViewModeButton = (button) => {
    const isSelected = viewMode === button;
    const backgroundcolor = isSelected
      ? themeContext.accentColor
      : "transparent";
    const iconColor = isSelected ? "#fff" : themeContext.textDark;

    const style = {
      WiewModeButton: {
        marginLeft: 4,
        height: 36,
        borderRadius: 18,
        backgroundColor: `${backgroundcolor}`,
      },
    };

    const handleClick = (button) => {
      setViewMode(button);
      history.push("/");
    };

    return (
      <Button
        key={button}
        icon
        style={style.WiewModeButton}
        onClick={() => handleClick(button)}
      >
        <Icon name={button} style={{ color: iconColor }} />
      </Button>
    );
  };

  const renderViewModes = () => (
    <ViewModesWrapper>
      {VIEW_MODE_BUTTONS.map(renderViewModeButton)}
    </ViewModesWrapper>
  );

  // eslint-disable-next-line
  let content;

  switch (viewMode) {
    case TURBINE_VIEW_MODES.GRID:
      content = <WindTurbinesGrid {...props} />;
      break;
    case TURBINE_VIEW_MODES.MAP:
      content = <WindTurbinesMap {...props} />;
      break;
    case TURBINE_VIEW_MODES.LIST:
      content = <WindTurbinesList {...props} />;
      break;

    default:
      break;
  }

  const [{ loading, data }] = useAxios({
    url: `/windTurbines/${windTurbineId}`,
    method: "GET",
  });

  const val = React.useRef();
  React.useEffect(() => {
    val.current = windTurbines;
  }, [windTurbines]);

  const windTurbineData = data || windTurbine;

  useEffect(() => {
    if (data) {
      dispatch({ type: LOAD_WIND_TURBINE, payload: { [data.id]: data } });
      loadWindTurbineParams(windTurbineData.id, windTurbineData.type);
    }
  }, [data]); // eslint-disable-line

  useEffect(() => {
    setWindTurbine(windTurbineData);
  }, [unsubscribeFromLiveParams, windTurbineId, windTurbineData]);

  const renderContent = () => {
    let Content = null;
    switch (activeItem) {
      case "LIVE":
        Content = (
          <LiveContent
            windTurbine={windTurbineData}
            subscribeToLiveParams={subscribeToLiveParams}
            unsubscribeFromLiveParams={unsubscribeFromLiveParams}
          />
        );
        break;
      case "HISTORY":
        Content = <HistoryContent windTurbine={windTurbineData} />;
        break;
      case "ADVANCED":
        Content = (
          <AdvancedContent
            windTurbine={windTurbineData}
            subscribeToLiveParams={subscribeToLiveParams}
            unsubscribeFromLiveParams={unsubscribeFromLiveParams}
          />
        );
        break;
      case "SERVICE LOGS":
        Content = <ServiceLogs />;
        break;
      default:
        break;
    }
    return Content;
  };

  const onMenuItemClick = (item) => {
    setActiveItem(item);
  };

  const renderMenu = () => (
    <Menu
      backgroundcolor={themeContext.cardBackgroundColor}
      links={ITEMS}
      defaultActiveItem={ITEMS[0].label}
      onItemClick={onMenuItemClick}
      asAccordionMobile={false}
      withoutNavigation
    />
  );

  let Content;

  if (!loading && !windTurbineData) {
    Content = (
      <EmptyView
        style={{ marginTop: 100 }}
        title="Wind turbine not found or you have no access to it"
      />
    );
  } else if (loading || !windTurbineData) {
    Content = <Loader active inline="centered" />;
  } else {
    Content = (
      <Card raised fluid style={{ margin: "0rem" }}>
        <CardContent>
          <Sticky context={contextRef}>
            <CardHeader
              fullDetails
              renderMenu={renderMenu}
              hideRight={activeItem === "HISTORY"}
              hideRPM={activeItem === "ADVANCED"}
              windTurbine={windTurbineData}
              signal={2}
            />
          </Sticky>
          {renderContent()}
        </CardContent>
      </Card>
    );
  }

  return (
    <div ref={contextRef}>
      <ContentContainer renderRightContent={renderViewModes}>
        {Content}
      </ContentContainer>
    </div>
  );
};

const mapStateToProps = (state) => ({
  viewMode: state.dashboard.viewMode,
  windTurbines: state.windTurbines,
});

const mapDispatchToProps = (dispatch) => ({
  setViewMode: (viewMode) => dispatch(setViewMode(viewMode)),
  loadWindTurbineParams: (id, type) =>
    dispatch(loadWindTurbineParams(id, type)),
  subscribeToLiveParams: (windTurbines) =>
    dispatch(subscribeToLiveParams(windTurbines, "monitoring")),
  unsubscribeFromLiveParams: (windTurbines) =>
    dispatch(unsubscribeFromLiveParams(windTurbines, "monitoring")),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(Details);
