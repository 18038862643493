import { connect } from 'react-redux';

import CardHeader from 'components/dashboard/CardHeader';
import { getLiveParams } from "selectors/liveParams";

const LIVE_PARAMS = ['err', 'power', 'rpm_rotor', 'state', 'kwh_meter', 'timestamp'];

const mapStateToProps = (state, ownProps) => ({
  liveProps: getLiveParams(state, ownProps.windTurbine.id, LIVE_PARAMS),
  turbineState: state.turbineState[ownProps.windTurbine.id]
});

export default connect(mapStateToProps)(CardHeader);
