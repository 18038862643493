import React from "react";
import PropTypes from "prop-types";
import Circle from "./Circle";
import styled from "styled-components";
import { Text } from "components/shared";

const ContentWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 50%;
  right: 50%;
  top: 35%;
  background-color: red;
`;

const Title = styled((props) => <Text {...props} />)`
  font-weight: bold;
  color: ${(props) => props.theme.textDark};
`;

const Subtitle = styled((props) => <Text {...props} />)`
  font-weight: 300;
  margin-top: 2px;
  color: ${(props) => props.theme.textMedium};
`;

const CircleWrapper = styled.div`
  max-width: 100%;
  position: relative;
  font-size: ${(props) => (props.width || 120) * 0.15 + 6}px;
`;

const ErrorText = styled((props) => <Text {...props} />)`
  &&& {
    font-weight: bold;
    width: 8rem;
    color: red;
    text-align: center;
    margin-top: 0.5rem;
  }

  @media (max-width: 767px) {
    &&& {
      display: none;
    }
  }
`;

const ProgressTypes = ["line", "circle", "dashboard"];
const ProgressStatuses = ["normal", "exception", "active", "success"];

const Progress = ({
  prefixCls,
  width,
  strokeWidth,
  trailColor,
  strokeLinecap,
  gapPosition,
  gapDegree,
  type,
  strokeColor,
  value,
  maxValue,
  minValue,
  icon,
  unit,
  hideValueText,
  fulldetails,
  err,
}) => {
  const circleWidth = strokeWidth || 6;
  const gapPos = gapPosition || (type === "dashboard" && "bottom") || "top";
  const gapDeg = gapDegree || (type === "dashboard" ? 75 : undefined);

  let val =
    value === undefined ? minValue : value > maxValue ? maxValue : value;
  const valToShow = val;
  val = val < minValue ? minValue : val;

  const progress = (val * 100) / maxValue;
  return (
    <CircleWrapper width={width}>
      <Circle
        percent={progress}
        strokeWidth={circleWidth}
        trailWidth={circleWidth}
        strokeColor={strokeColor}
        strokeLinecap={strokeLinecap}
        trailColor={trailColor}
        prefixCls={prefixCls}
        gapDegree={gapDeg}
        gapPosition={gapPos}
      />
      <ContentWrapper>
        {icon}
        {!hideValueText && <Title size={20}>{valToShow}</Title>}
        <Subtitle size={18}>{unit}</Subtitle>
        {fulldetails && unit === "kW" && (
          <ErrorText style={{ fontSize: "clamp(1rem, 1vw, 20px)" }}>
            {err}
          </ErrorText>
        )}
      </ContentWrapper>
    </CircleWrapper>
  );
};

Progress.propTypes = {
  status: PropTypes.oneOf(ProgressStatuses),
  type: PropTypes.oneOf(ProgressTypes),
  showInfo: PropTypes.bool,
  percent: PropTypes.number,
  width: PropTypes.number,
  strokeWidth: PropTypes.number,
  strokeLinecap: PropTypes.oneOf(["round", "square"]),
  strokeColor: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  trailColor: PropTypes.string,
  format: PropTypes.func,
  gapDegree: PropTypes.number,
  unit: PropTypes.string,
  hideValueText: PropTypes.bool,
  minValue: PropTypes.number,
};

Progress.defaultProps = {
  type: "line",
  percent: 0,
  showInfo: true,
  trailColor: "#efefef",
  size: "default",
  gapDegree: 0,
  strokeLinecap: "round",
  hideValueText: false,
  minValue: 0,
};

export default Progress;
