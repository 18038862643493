import { LOAD_WEATHER_FORECAST, LOAD_CURRENT_WEATHER } from 'actions/weather';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_WEATHER_FORECAST.REQUEST: {
      return {
        ...state,
        forecast: {
          ...state.forecast,
          [action.payload.coordinatesKey]: {
            ...state.forecast ? state.forecast[action.payload.coordinatesKey] : {},
            isLoading: true,
            requestDate: action.payload.requestDate
          }
        }
      };
    }

    case LOAD_WEATHER_FORECAST.SUCCESS:
      return {
        ...state,
        forecast: {
          ...state.forecast,
          [action.payload.coordinatesKey]: {
            ...state.forecast ? state.forecast[action.payload.coordinatesKey] : {},
            isLoading: false,
            data: action.payload.data,
          }
        }
      };

    case LOAD_WEATHER_FORECAST.FAILURE:
      return {
        ...state,
        forecast: {
          ...state.forecast,
          [action.payload.coordinatesKey]: {
            ...state.forecast ? state.forecast[action.payload.coordinatesKey] : {},
            isLoading: false,
            error: action.payload.error
          }
        }
      };

    case LOAD_CURRENT_WEATHER.REQUEST:
      return {
        ...state,
        current: {
          ...state.current,
          [action.payload.coordinatesKey]: {
            ...state.current ? state.current[action.payload.coordinatesKey] : {},
            isLoading: true,
            requestDate: action.payload.requestDate
          }
        }
      };

    case LOAD_CURRENT_WEATHER.SUCCESS:
      return {
        ...state,
        current: {
          ...state.current,
          [action.payload.coordinatesKey]: {
            ...state.current ? state.current[action.payload.coordinatesKey] : {},
            isLoading: false,
            data: action.payload.data,
          }
        }
      };

    case LOAD_CURRENT_WEATHER.FAILURE:
      return {
        ...state,
        current: {
          ...state.current,
          [action.payload.coordinatesKey]: {
            ...state.current ? state.current[action.payload.coordinatesKey] : {},
            isLoading: false,
            error: action.payload.error
          }
        }
      };

    default: return state;
  }
}
