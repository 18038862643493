import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import useAxios from 'axios-hooks';
import ServiceLogs from 'components/advanced/ServiceLogs';

const convertLogToParam = l => ({
  user: l.user.name, notes: l.notes, date: moment(l.updatedAt).format('D MMMM YYYY, HH:mm:ss'), photo: l.photo
});

const ServiceLogsContainer = ({ match, user, windTurbine }) => {
  const windTurbineId = match.params.id;

  const [logs, setLogs] = useState([]);

  const [{ data }] = useAxios({
    url: `/serviceLogs`,
    params: {
      windTurbineId
    },
    method: "GET",
    fetchPolicy: 'no-cache'
  });


  const [, createServiceLog] = useAxios({
    url: `/serviceLogs`,
    params: {
      windTurbineId
    },
    method: "POST"
  }, {
    manual: true
  });

  useEffect(() => {
    if (data) {
      const serviceLogs = data || [];
      setLogs(serviceLogs.map(convertLogToParam));
    }

    return () => {
      setLogs([]);
    }
  }, [data]);

  const onAddLog = async (notes, base64EncodedImages = []) => {
    try {
      const result = await createServiceLog({
        data: {
          windTurbineId,
          notes,
          photos: base64EncodedImages
        }
      });

      const newLog = result.data;
      setLogs([convertLogToParam(newLog), ...logs]);
      toast.success('Log entry created successfully');
    }
    catch (e) {
      toast.error(e.response.data.error);
    }
  };

  return (
    <ServiceLogs
      logs={logs}
      enableAdd={user.role !== 'CUSTOMER'}
      addLog={onAddLog}
      addLogHeader={`${windTurbine.name} - Add service entry (${user.email})`}
    />
  );
};

const mapStateToProps = (state, ownProps) => ({
  user: state.session.user,
  windTurbine: state.windTurbines[ownProps.match.params.id],
});

export default withRouter(connect(mapStateToProps)(ServiceLogsContainer));
