import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Container, Breadcrumb, Icon } from "semantic-ui-react";
import styled, { ThemeContext } from "styled-components";

const BreadcrumbWrapper = styled.div``;

const StyledContainer = styled(Container)``;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
`;

const ContentContainer = ({
  children,
  withDashboardLink,
  renderRightContent,
}) => {
  const themeContext = useContext(ThemeContext);

  const style = {
    smallBreadcrumb: {
      display: "flex",
      alignItems: "center",
    },
    breadCrumbIcon: {
      color: themeContext.textDark,
    },
    breadCrumbSection: {
      color: themeContext.textMediumDark,
      fontWeight: "600",
    },
  };

  return (
    <StyledContainer fluid>
      <HeaderWrapper>{renderRightContent()}</HeaderWrapper>
      {children}
    </StyledContainer>
  );
};

ContentContainer.propTypes = {
  renderRightContent: PropTypes.func,
};

ContentContainer.defaultProps = {
  renderRightContent: () => null,
};

export default ContentContainer;
