import { connect } from 'react-redux';

import CardFooter from 'components/dashboard/CardFooter';
import { getLiveParams, getTotalProductionLive } from "selectors/liveParams";
import { getTurbineParamsDefinitionByKey } from "selectors/utils";

const LIVE_PARAMS = 'all' // ['kwh_meter'];
const TURBINE_PARAMAS_DEFINITION = ['wind','power', 'kwh_meter']
const mapStateToProps = (state, ownProps) => ({
  liveProps: getLiveParams(state, ownProps.windTurbine.id, LIVE_PARAMS),
  productions: getTotalProductionLive(state, ownProps.windTurbine.id),
  turbineParams: getTurbineParamsDefinitionByKey(state, ownProps.windTurbine.id, TURBINE_PARAMAS_DEFINITION),
});

export default connect(mapStateToProps)(CardFooter);
