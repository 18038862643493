import React from 'react';

import LoginForm from 'containers/auth/LoginForm';
import AuthRoute from './AuthRoute';

const SignIn = () => {
  return (
    <AuthRoute>
      <LoginForm />
    </AuthRoute>
  )
};

export default SignIn;
