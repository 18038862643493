import { SET_VIEW_MODE } from "../constants";
import { SET_PAGE_NUMBER } from "../constants";
import { SET_TOTAL_PAGE_NUMBER } from "../constants";
import { LOAD_WIND_TURBINES } from "../constants";
import { ITEMS_PER_PAGE } from "../constants";

export const setViewMode = (viewMode) => ({
  type: SET_VIEW_MODE,
  payload: { viewMode },
});

export const setPageNumber = (pageNumber) => ({
  type: SET_PAGE_NUMBER,
  payload: { pageNumber },
});

export const setTotalPageNumber = (contentRage) => ({
  type: SET_TOTAL_PAGE_NUMBER,
  payload: { totalPageNumber: Math.ceil(contentRage.size / ITEMS_PER_PAGE) },
});

export const loadTurbines = (data) => ({
  type: LOAD_WIND_TURBINES,
  payload: data,
});
