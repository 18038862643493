import base64 from 'base-64';
import { API_URL } from '../constants';

export function shouldDisplayParam(p) {
  if (p["graph display"] && p["graph display"] === "show") return true;
  if (p["graph display"] && (p["graph display"] === "Not used" || p["graph display"] === "not show")) return false;
  return true;
}

export function dec2bin(dec, length) {
  if (!dec) return "";
  return numToString(dec, 2, length);
}

function padStart(string, length, char) {
  //  can be done via loop too:
  //    while (length-- > 0) {
  //      string = char + string;
  //    }
  //  return string;
  return length > 0 ?
    padStart(char + string, --length, char) :
    string;
}

function numToString(num, radix, length = num.length) {
  const numString = num.toString(radix);
  return numString.length === length ?
    numString :
    padStart(numString, length - numString.length, "0")
}

function composeRequest(urlPath, method, bodyData) {
  let username = 'restmaster';
  let password = 'wf98cj45uvjy4i56cgw#6';
  return new Request(`${API_URL}/iotgateway` + urlPath,
    {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Basic ' + base64.encode(username + ":" + password),
      },
      body: JSON.stringify(bodyData),
    });
}

export async function writeCommand(windTurbineIdentifier, action) {
  let bodyData = [{
    id: windTurbineIdentifier + ".Commands." + action,
    v: 1
  }];
  const urlPath = "/write";
  const method = "POST";
  let req = composeRequest(urlPath, method, bodyData);

  try {
    let isFirstCallSuccess;
    let response = await fetch(req);
    let jsonData = await response.json()
    isFirstCallSuccess = jsonData.s;

    if (isFirstCallSuccess === true) {
      bodyData[0].v = 0;
      req = composeRequest(urlPath, method, bodyData);
      response = await fetch(req);
      jsonData = await response.json();
    }

    return jsonData;
  }
  catch (error) {
    // debugger;
    console.error('Error:', error);
    return "Error" + error;
  }

}

export async function writeParam(windTurbineIdentifier, action, value) {
  let bodyData = [{
    id: windTurbineIdentifier + ".Parameters." + action,
    v: value
  }];
  let req = composeRequest("/write", "POST", bodyData);

  try {
    let response = await fetch(req);
    let jsonData = await response.json()

    return jsonData;
  }
  catch (error) {
    console.error('Error:', error);
    return "Error" + error;
  }

}

