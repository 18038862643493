import React, { useContext } from "react";
import { Card, Popup, Grid, Icon } from "semantic-ui-react";
import { ScreenSizes, Text } from "components/shared";
import styled, { ThemeContext } from "styled-components";
import PropTypes from "prop-types";

import "flag-icon-css/css/flag-icon.css";
import "responsive-semantic-ui.css";

const Wrapper = styled((props) => <Card.Header {...props} />)`
  background-color: ${(props) => props.themecontext.cardBackgroundColor}
    .HeaderSide {
    padding: 0.5rem;
  }
`;

const Name = styled((props) => <Text {...props} />)`
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  color: ${(props) => props.theme.textDark};
  font-weight: 900;
  text-overflow: ellipsis;
  align-items: center;
`;

const Power = styled((props) => <Text {...props} />)`
  color: ${(props) => props.theme.textDark};
  font-weight: 100;
  white-space: nowrap;
`;

const MainGridColumn = styled((props) => <Grid.Column {...props} />)`
  flex: ${(props) => (props.fullDetails ? 1 : undefined)};
  margin: auto;
`;

const CardHeader = ({ renderMenu, fullDetails, windTurbine, liveProps }) => {
  const { state } = liveProps;
  const { name } = windTurbine;
  const themeContext = useContext(ThemeContext);

  const renderLiveStates = () => (
    <Grid style={{ padding: "1rem" }}>
      <Grid.Row
        style={{ padding: fullDetails ? "1rem 0rem 0rem 0rem" : "0rem" }}
      >
        <Name
          style={{
            marginRight: fullDetails ? undefined : "auto",
            padding: "0rem",
            minHeight: "2rem",
          }}
          size={fullDetails ? 24 : 16}
        >
          {name}
        </Name>
      </Grid.Row>
      <Grid.Row
        style={{
          padding: fullDetails ? "1rem 0rem 1rem 0rem" : "0rem",
          justifyContent: "space-between",
        }}
      >
        <Popup
          content="Current state"
          trigger={
            <Text style={{ color: "gray" }} size={fullDetails ? 24 : 16}>
              {state && state.length > 0 ? state : "N/A"}
            </Text>
          }
        />
        {state !== 0 && fullDetails === false && (
          <Icon
            style={{
              display: "flex",
            }}
            color="grey"
            name="signal"
          ></Icon>
        )}
      </Grid.Row>
    </Grid>
  );

  return (
    <Wrapper themecontext={themeContext}>
      <Grid
        stretched
        stackable
        verticalAlign="middle"
        padded
        columns={fullDetails ? 2 : 1}
      >
        <Grid.Row>
          <MainGridColumn fulldetails={fullDetails ? 1 : 0}>
            {renderLiveStates()}
          </MainGridColumn>
          <MainGridColumn className={!fullDetails ? "computer only" : ""}>
            {renderMenu()}
          </MainGridColumn>
        </Grid.Row>
      </Grid>
    </Wrapper>
  );
};

CardHeader.propTypes = {
  renderMenu: PropTypes.func,
  hideRight: PropTypes.bool,
  hideRPM: PropTypes.bool,
  fullDetails: PropTypes.bool,
};

CardHeader.defaultProps = {
  renderMenu: () => null,
  hideRight: false,
  hideRPM: false,
  fullDetails: false,
};

export default React.memo(CardHeader);
