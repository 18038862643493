import React, { useContext, useState } from 'react';
import { Button, Accordion, Modal, Icon } from 'semantic-ui-react';
import { ThemeContext } from 'styled-components';

const HelpAndFAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const themeContext = useContext(ThemeContext);

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;

    setActiveIndex(newIndex);
  };

  return (
    <Modal trigger={<Button style={{ color: `${themeContext.primaryButtonText}`, backgroundColor: `${themeContext.primaryButtonColor}`}} secondary>Help & FAQ</Button>}>
      <Modal.Header>Help & FAQ</Modal.Header>
      <Modal.Content>
        <Accordion>
          <Accordion.Title active={activeIndex === 0} index={0} onClick={handleClick}>
            <Icon name='dropdown' />
            What is a dog?
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 0}>
            <p>
              A dog is a type of domesticated animal. Known for its loyalty and faithfulness, it can
              be found as a welcome guest in many households across the world.
            </p>
          </Accordion.Content>

          <Accordion.Title active={activeIndex === 1} index={1} onClick={handleClick}>
            <Icon name='dropdown' />
            What kinds of dogs are there?
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 1}>
            <p>
              There are many breeds of dogs. Each breed varies in size and temperament. Owners often
              select a breed of dog that they find to be compatible with their own lifestyle and
              desires from a companion.
            </p>
          </Accordion.Content>

          <Accordion.Title active={activeIndex === 2} index={2} onClick={handleClick}>
            <Icon name='dropdown' />
            How do you acquire a dog?
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 2}>
            <p>
              Three common ways for a prospective owner to acquire a dog is from pet shops, private
              owners, or shelters.
            </p>
            <p>
              A pet shop may be the most convenient way to buy a dog. Buying a dog from a private
              owner allows you to assess the pedigree and upbringing of your dog before choosing to
              take it home. Lastly, finding your dog from a shelter, helps give a good home to a dog
              who may not find one so readily.
            </p>
          </Accordion.Content>
        </Accordion>
      </Modal.Content>
    </Modal>
  );
};

HelpAndFAQ.propTypes = {};

HelpAndFAQ.defaultProps = {};

export default HelpAndFAQ;
