import React, { useEffect } from "react";

import CardFooter from "containers/dashboard/CardFooter";
import DashboardCardContent from "containers/dashboard/DashboardCardContent";
import LiveControlsMenu from "containers/live/LiveControlsMenu";

const Live = ({
  windTurbine,
  subscribeToLiveParams,
  unsubscribeFromLiveParams,
}) => {
  useEffect(() => {
    subscribeToLiveParams([windTurbine, { from: "monitoring" }]);
  });

  useEffect(() => {
    return () => {
      console.log("++ UNMOUNT DETAIL LIVE", windTurbine.id);
      windTurbine.from = "monitoring";
      //need this to have the expected format in the unsubscribeFromLiveParams() function
      let wtArr = [];
      wtArr[windTurbine.id] = windTurbine;
      unsubscribeFromLiveParams({ ...wtArr });
    };
  }, [unsubscribeFromLiveParams]);

  return (
    <div>
      <DashboardCardContent fullDetails windTurbine={windTurbine} />
      <CardFooter fullDetails windTurbine={windTurbine}>
        <LiveControlsMenu windTurbine={windTurbine} />
      </CardFooter>
    </div>
  );
};

export default React.memo(Live);
