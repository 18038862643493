import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import CardHeader from 'containers/dashboard/CardHeader';
import CardFooter from 'containers/dashboard/CardFooter';
import DashboardCardContent from 'containers/dashboard/DashboardCardContent';
import { Card, CardContent as CardContentContainer } from 'components/shared';

const WindmillCard = (props) => {
  const {
    disabled, fullDetails, onCardClick, liveProps, windTurbine, productions
  } = props;
  const { signal } = liveProps;

  return (
    <Card islarge={fullDetails.toString()} raised fluid onClick={disabled ? null : onCardClick}>
      <CardContentContainer style={{ padding: 16 }}>
        <CardHeader
          fullDetails={fullDetails}
          windTurbine={windTurbine}
        />
        <DashboardCardContent
          signal={signal}
          fullDetails={fullDetails}
          windTurbine={windTurbine}
        />
        <CardFooter
          fullDetails={fullDetails}
          productions={productions}
          windTurbine={windTurbine}
        />
      </CardContentContainer>
    </Card>
  );
};

WindmillCard.propTypes = {
  disabled: PropTypes.bool,
  fullDetails: PropTypes.bool,
  liveProps: PropTypes.shape({
    name: PropTypes.string,
    power: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    signal: PropTypes.string,
    productions: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  onCardClick: PropTypes.func,
};

WindmillCard.defaultProps = {
  disabled: false,
  fullDetails: false,
  liveProps: {
    name: null,
    power: null,
    signal: null,
    productions: [],
  },
  onCardClick: () => { },
};

export default withRouter(React.memo(WindmillCard));
