import React from "react";
import styled from "styled-components";
import { Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import { Button, Logo } from "components/shared";
import AccountHeader from "containers/header/AccountHeader";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-items: center;
  padding: 0.5rem;
`;

const styles = {
  button: {
    visibility: "hidden",
    backgroundcolor: "transparent",
    boxShadow: "0 0 0 transparent",
    color: "#fff",
  },
};

const MobileHeader = ({ visibility }) => {
  return (
    <Wrapper className={visibility}>
      <Button style={styles.button} icon>
        <Icon name="align left" />
      </Button>
      <Logo />
      <AccountHeader />
    </Wrapper>
  );
};

MobileHeader.propTypes = {};

MobileHeader.defaultProps = {};

const mapStateToProps = (state) => ({
  theme: state.settings.theme,
});

export default connect(mapStateToProps)(MobileHeader);
