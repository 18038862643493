import React from "react";
import ControlButton from "./ControlButton";
import ExecuteActionButton from "containers/common/ExecuteActionButton";

const LiveControlButtons = ({ buttons, windTurbineIdentifier }) => {
  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {buttons.map((b) => (
        <ExecuteActionButton
          key={b.cmd || b.function}
          action={b.dbname}
          name={b.function}
          windTurbineIdentifier={windTurbineIdentifier}
        >
          <ControlButton removePadding={true}>{b.function}</ControlButton>
        </ExecuteActionButton>
      ))}
    </div>
  );
};

export default LiveControlButtons;
