import { pick } from 'lodash';
import { getStateLocalized } from "utils/errors";
import { getTurbineParamsDefinitionByKey } from "./utils";
import { shouldDisplayParam } from 'utils/params';

const emptyObj = (params) => {
  const obj = {};
  params.forEach(p => obj[p] = 0);
  return obj;
};

const empty = {};

export const getLiveParams = (state, windTurbineId, params) => {
  const slice = state.liveParams[windTurbineId];
  if (!slice) {
    return params === 'all' ? empty : emptyObj(params);
  }

  let filtered = slice;
  if (params !== 'all') {
    filtered = pick(slice, params);
  }
  const response = {};

  Object.keys(filtered).forEach(key => {
    if (key === 'err' || key === 'error') {
      const failureParams = state.turbineParams[windTurbineId] && state.turbineParams[windTurbineId].Failure;
      if (failureParams) {
        const failure = failureParams.find(f => f.number === filtered[key].v);
        if (!failure) { response[key] = "Unknown error code. Define " + filtered[key].v + " in excel"; }
        else if (failure.text.trim() !== 'System OK') {
          response[key] = failure.text;
        } else {
          response[key] = null;
        }
      }
    } else if (key === 'state') {
      const stateParams = state.turbineParams[windTurbineId] && state.turbineParams[windTurbineId].State;
      if (stateParams) {
        const stateParam = stateParams.find(f => f.index === filtered[key].v);
        if (!stateParam && filtered[key].v !== 0) {
          response[key] = "Unknown state code. Define " + filtered[key].v + " in excel";
        } else { response[key] = filtered[key].v === 0 ? '' : stateParam.text; }
      }

    } else {
      const paramsDef = getTurbineParamsDefinitionByKey(state, windTurbineId, [key]);
      if (paramsDef && paramsDef.length > 0) {
        const scale = paramsDef[0].scale || 1;
        response[key] = filtered[key].v / scale;
      }
    }
  });

  return response;
};

export const getLiveGraphParams = (state, windTurbineId) => {
  const slice = state.liveParams[windTurbineId];
  if (!slice) {
    return empty;
  }

  const monitoringState = state.turbineParams[windTurbineId];
  let monitoringParams = [];
  if (monitoringState) {
    monitoringParams = Object.values(monitoringState).flat().filter(shouldDisplayParam);
  }
  const response = {};
  Object.keys(slice).forEach(key => {
    if (key === 'err' || key === "error") {
      const failureParams = state.turbineParams[windTurbineId] && state.turbineParams[windTurbineId].Failure;
      if (failureParams) {
        const failure = failureParams.find(f => f.number === slice[key].v);
        if (!failure) {
          response[key] = {
            timestamp: slice.timestamp,
            value: "Unknown error code. Define " + slice[key].v + " in excel"
          };
        }
        else {
          response[key] = {
            timestamp: slice.timestamp,
            value: monitoringState.Failure.find(f => f.number === slice[key].v).number || slice[key.v],
          }
        }
        // } else {
        //   response[key] = {
        //     timestamp: slice.timestamp,
        //     value: slice[key].v, // monitoringState.Failure.find(f => f.number === slice[key].v) || slice[key.v],
        //   }
        // }
      }
    } else if (key === 'state') {
      response[key] = getStateLocalized(slice[key].v);
    } else {
      const param = monitoringParams.find(
        p => (p.dbname && p.dbname.toLowerCase() === key.toLowerCase())
          || (p.name && p.name.toLowerCase() === key.toLowerCase())
      );
      const scale = param ? param.scale : 1;
      response[key] = {
        timestamp: slice.timestamp,
        value: slice[key].v / scale,
      };
    }
  });

  return response;
};

export const getTotalProductionLive = (state, windTurbineId) => {
  if (!state.turbineParams[windTurbineId]) return [];

  const paramKey = "kwh_meter";
  const totalProductionParam = state.turbineParams[windTurbineId]["meter"]
    ? state.turbineParams[windTurbineId]["meter"].find(p => p.dbname.toLowerCase() === "kwh_meter")
    : null;

  if (state.liveParams[windTurbineId] && state.liveParams[windTurbineId][paramKey]) {
    const liveParam = state.liveParams[windTurbineId][paramKey];
    const scale = totalProductionParam ? totalProductionParam.scale : 1;

    totalProductionParam.value = liveParam.v / scale;
    totalProductionParam.timestamp = liveParam.t;
  }


  return [totalProductionParam];
}
