import React, { useContext, useState, useEffect } from "react";
import moment from "moment";
import styled, { ThemeContext } from "styled-components";
import LineChart from "./LineChart";
import BarChart from "./RoundedBarsChart";
import { Button } from "components/shared";
import { Dropdown } from "semantic-ui-react";
import { sortByKey } from "utils/shared";

const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledDropdown = styled(Dropdown)``;

const LineControlsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 24px;
`;

const DropdownWrapper = styled.div`
  display: flex;
`;

const DropdownIndicator = styled.div`
  height: 14px;
  width: 14px;
  border-radius: 7px;
  background-color: ${(props) => props.color};
  margin-right: 8px;
  margin-bottom: 1px;
`;

const ResetZoomButton = styled(Button)`
  &&& {
    background-color: transparent;
    color: ${(props) => props.theme.textDark};
    border: 2px solid ${(props) => props.theme.accentColor};
    border-radius: 5px;
    box-shadow: 0;
  }
`;

const CHART_TIME_UNITS = {
  Hourly: "hour",
  Daily: "day",
  Monthly: "month",
};

const config = (
  theme,
  chartType,
  dataset1,
  dataset2,
  setIsZooming,
  isZooming,
  firstLineItem,
  secondLineItem,
  firstLineParam,
  secondLineParam,
  turbineParams
) => {
  return {
    data: {
      labels: dataset1.labels,
      datasets: [
        {
          backgroundcolor: theme.graphAxle1,
          borderColor: theme.graphAxle1,
          data: dataset1.data,
          label: firstLineItem,
          unit: firstLineParam ? firstLineParam.unit : "",
          details:
            firstLineParam.name.toLowerCase() === "error"
              ? turbineParams.Failure
              : null,
          fill: false,
          yAxisID: "y-axis-1",
          pointRadius: 0,
          borderWidth: 2,
          spanGaps: false,
        },
        {
          backgroundcolor: theme.graphAxle2,
          borderColor: theme.graphAxle2,
          data: dataset2.data,
          label: secondLineItem,
          unit: secondLineParam ? secondLineParam.unit : "",
          details:
            secondLineParam.name.toLowerCase() === "error"
              ? turbineParams.Failure
              : null,
          fill: false,
          yAxisID: "y-axis-2",
          pointRadius: 0,
          borderWidth: 2,
          spanGaps: false,
        },
      ],
    },
    options: {
      animation: {
        duration: 0, // general animation time
      },
      legend: {
        display: false,
      },
      tooltips: {
        mode: "index",
        intersect: false,
        backgroundcolor: "rgba(0, 0, 0, 0.5)",
        bodySpacing: 4,
        callbacks: {
          label: function (tooltipItem, data) {
            var label = data.datasets[tooltipItem.datasetIndex].label || "";
            if (label) {
              label += ": ";
            }
            let value = tooltipItem.yLabel;
            const details = data.datasets[tooltipItem.datasetIndex].details;
            if (details) {
              value = `${
                details.find((d) => d.number === value).text
              } (${value})`;
            }
            label += `${value} ${
              data.datasets[tooltipItem.datasetIndex].unit || ""
            }`;
            return label;
          },
        },
      },
      scales: {
        xAxes: [
          {
            type: "time",
            offset: true,
            bounds: "ticks",
            time: {
              unit: CHART_TIME_UNITS[chartType],
              stepSize: 1,
              displayFormats: {
                hour: "HH:mm",
              },
            },
            gridLines: {
              display: false,
            },
            ticks: {
              fontColor: theme.textMedium,
            },
          },
        ],
        yAxes: [
          {
            id: "y-axis-1",
            position: "left",
            gridLines: {
              drawBorder: false,
              color: theme.controlsBackgroundColor,
              zeroLineColor: theme.controlsBackgroundColor,
            },
            ticks: {
              fontColor: theme.textMedium,
              beginAtZero: firstLineItem === "Production" ? false : true,
              // precision: 0,
            },
          },
          {
            id: "y-axis-2",
            position: "right",
            gridLines: {
              drawBorder: false,
              color: theme.controlsBackgroundColor,
              zeroLineColor: theme.controlsBackgroundColor,
            },
            ticks: {
              fontColor: theme.textMedium,
              beginAtZero: secondLineItem === "Production" ? false : true,
              // precision: 0,
            },
          },
        ],
      },
      plugins: {
        streaming: false,
        zoom: {
          zoom: {
            enabled: true,
            drag: true,
            mode: "x",
            onZoomComplete: () => setIsZooming(true),
          },
        },
      },
    },
  };
};

const DataGraph = ({
  height = 70,
  chartType,
  data,
  detailedData,
  monitoringParams,
  turbineParams,
  firstMonitoredParameter,
  secondMonitoredParameter,
  onfirstParameterChange,
  onsecondParameterChange,
}) => {
  const themeContext = useContext(ThemeContext);

  const [isZooming, setIsZooming] = useState(false);
  const [cfg, setCfg] = useState();
  //display chart only when params are defined

  const [showChart, setShowChart] = useState(
    typeof firstMonitoredParameter != "undefined" &&
      typeof secondMonitoredParameter != "undefined"
      ? true
      : false
  );

  useEffect(() => {
    if (showChart) {
      const getDatasetData = (datasetIndex) => {
        let dsLabels = [];
        let dsData = [];
        const first = monitoringParams.find(
          (p) => p.name === firstMonitoredParameter.name
        );
        const second = monitoringParams.find(
          (p) => p.name === secondMonitoredParameter.name
        );
        onfirstParameterChange({}, first);
        onsecondParameterChange({}, second);
        const key = datasetIndex === 0 ? first.dbname : second.dbname;
        const scale = datasetIndex === 0 ? first.scale || 1 : second.scale || 1;

        const graphData =
          detailedData && detailedData.length > 0 ? detailedData : data; //isZooming &&
        graphData.forEach((d) => {
          if (!d.timestamp) {
            return;
          }

          const label = moment(Math.floor(d.timestamp));

          dsData.push(parseFloat(d.values[key.toUpperCase()]) / scale);
          dsLabels.push(label);
        });

        return {
          labels: dsLabels,
          data: dsData,
          unit: datasetIndex === 0 ? first.unit : second.unit,
        };
      };

      if (data) {
        const dataset1 = getDatasetData(0);
        const dataset2 = getDatasetData(1);
        const conf = config(
          themeContext,
          chartType,
          dataset1,
          dataset2,
          setIsZooming,
          isZooming,
          firstMonitoredParameter.name,
          secondMonitoredParameter.name,
          firstMonitoredParameter,
          secondMonitoredParameter,
          turbineParams
        );
        setCfg(conf);
      }
    }
    // eslint-disable-next-line
  }, [
    chartType,
    data,
    detailedData,
    firstMonitoredParameter,
    isZooming,
    secondMonitoredParameter,
    onfirstParameterChange,
    onsecondParameterChange,
  ]);

  if (!cfg) return null;

  const unsortedOption = monitoringParams.filter(
    (m) =>
      m.name !== firstMonitoredParameter.name &&
      m.name !== secondMonitoredParameter.name
  );
  const validOptions = sortByKey(unsortedOption, "name");
  const handleResetZoom = () => setIsZooming(false);

  if (showChart) {
    return (
      <ChartWrapper>
        {chartType === "Hourly" ? (
          <LineChart
            data={cfg.data}
            options={cfg.options}
            isZooming={isZooming}
          />
        ) : (
          <BarChart
            data={cfg.data}
            options={cfg.options}
            isZooming={isZooming}
          />
        )}
        <LineControlsWrapper>
          <DropdownWrapper>
            <DropdownIndicator color={themeContext.graphAxle1} />
            <StyledDropdown
              text={firstMonitoredParameter.name}
              direction="right"
              style={{ color: themeContext.textDark }}
              scrolling
            >
              <Dropdown.Menu>
                {validOptions.map((i) => (
                  <Dropdown.Item
                    key={i.name}
                    text={i.name}
                    unit={i.unit}
                    onClick={onfirstParameterChange}
                  />
                ))}
              </Dropdown.Menu>
            </StyledDropdown>
          </DropdownWrapper>

          {isZooming && (
            <ResetZoomButton onClick={handleResetZoom} secondary>
              Reset Zoom
            </ResetZoomButton>
          )}

          <DropdownWrapper>
            <DropdownIndicator color={themeContext.graphAxle2} />
            <StyledDropdown
              direction="left"
              text={secondMonitoredParameter.name}
              style={{ color: themeContext.textDark }}
              scrolling
            >
              <Dropdown.Menu>
                {validOptions.map((i) => (
                  <Dropdown.Item
                    key={`right:${i.name}`}
                    text={i.name}
                    unit={i.unit}
                    onClick={onsecondParameterChange}
                  />
                ))}
              </Dropdown.Menu>
            </StyledDropdown>
          </DropdownWrapper>
        </LineControlsWrapper>
      </ChartWrapper>
    );
  } else {
    return <ChartWrapper></ChartWrapper>;
  }
};

DataGraph.propTypes = {};

DataGraph.defaultProps = {};

export default React.memo(DataGraph);
