import React, { useEffect, useState } from 'react';
import moment from 'moment';
import media from 'styled-media-query';
import styled from 'styled-components';
import useAxios from 'axios-hooks'
import ControlsMenu from 'containers/advanced/ControlsMenu';
import Info from 'components/advanced/Info';

const InfoWrapper = styled.div`
display: grid;
grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
grid-column-gap: 1rem;
grid-row-gap: 1rem;
align-items: center;
justify-items: start;
`;

const DATA = [{
  icon: 'lightning',
  title: 'Rated turbine power',
  subtitle: '60 kW',
}, {
  icon: 'clock outline',
  title: '24h Connection availability',
  subtitle: '99.1%',
}];

const ActionLogsDataDefault = [{
  icon: 'calendar outline',
  title: 'Latest log',
  subtitle: 'N/A',
}, {
  icon: 'calendar alternate outline',
  title: 'First log',
  subtitle: 'N/A',
}];

const addLogToData = (logs) => {
  let logsDataConcat = DATA.concat(ActionLogsDataDefault);

  if (logs.length > 0) {
    const logsData = [{
      icon: 'calendar outline',
      title: 'Latest log',
      subtitle: moment(logs[0].updatedAt).format('D MMMM YYYY, HH:mm:ss'),
    }, {
      icon: 'calendar alternate outline',
      title: 'First log',
      subtitle: moment(logs[logs.length - 1].updatedAt).format('D MMMM YYYY, HH:mm:ss'),
    }];

    logsDataConcat = DATA.concat(logsData);
  }

  return logsDataConcat;
};

const Advanced = ({
  windTurbine,
  subscribeToLiveParams,
  unsubscribeFromLiveParams,
 }) => {
  const [actionLogs, setActionLogs] = useState([]);

  const [{ data }] = useAxios({
    url: `/actionLogs/${windTurbine.id}`,
    method: "GET",
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    subscribeToLiveParams([windTurbine, { from: "monitoring" }]);
  });

  useEffect(() => {
    return () => {
      console.log("++ UNMOUNT DETAIL LIVE", windTurbine.id);
      windTurbine.from = "monitoring";
      //need this to have the expected format in the unsubscribeFromLiveParams() function
      let wtArr = [];
      wtArr[windTurbine.id] = windTurbine;
      unsubscribeFromLiveParams({ ...wtArr });
    };
  }, [unsubscribeFromLiveParams]);

  useEffect(() => {
    if (data) {
      const actionLogs = data || [];
      setActionLogs(addLogToData(actionLogs));
    }
  }, [data]);

  const renderItems = () => (
    actionLogs.map(d => (
      <div key={d.title}>
        <Info icon={d.icon} title={d.title} subtitle={d.subtitle} />
      </div>
    ))
  );

  return (
    <div>
      <InfoWrapper>
        {renderItems()}
      </InfoWrapper>
      <ControlsMenu windTurbine={windTurbine} />
    </div>
  )
};

export default Advanced;
