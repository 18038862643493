import React, { useContext, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { Menu } from 'components/shared';

const ControlsWrapper = styled.div`
  margin-top: 24px;
  margin-bottom: -14px;
  padding-top: 8px;
  padding-bottom: 8px;
  
  @media (min-width: 768px) {
    background-color: ${props => props.theme.controlsBackgroundColor};
    margin-left: -14px;
    margin-right: -14px;
    padding-left: 24px;
    padding-right: 14.5px;
  }
`;

const ContentWrapper = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  margin-left: 16px;
`;

const ControlsMenu = ({ controls }) => {
  const [activeItem, setActiveItem] = useState(null);
  const themeContext = useContext(ThemeContext);

  const onItemClick = (item) => {
    setActiveItem(item);
  };

  return (
    <ControlsWrapper>
      <Menu
        links={controls}
        onItemClick={onItemClick}
        activeLink={activeItem}
        controlled
        backgroundcolor={themeContext.controlsBackgroundColor}
        withoutNavigation
      />
      <div className="mobile hidden">
        {activeItem && (
          <ContentWrapper>
            {controls.find(c => c.label === activeItem).content}
          </ContentWrapper>
        )}
      </div>
    </ControlsWrapper>
  );
};

ControlsMenu.propTypes = {};

ControlsMenu.defaultProps = {};

export default ControlsMenu;
