export const REMEMBER_LOGIN = "REMEMBER_LOGIN";
export const LOGGED_IN = "LOGGED_IN";

export const TURBINE_VIEW_MODES = Object.freeze({
  GRID: "grid layout",
  MAP: "map",
  LIST: "list layout",
});

export const WIND_TURBINE_ACTION = Object.freeze({
  STOP: "STOP",
  RESET: "RESET",
  MOTOR_START: "MOTOR_START",
  MOTOR_TOGGLE: "MOTOR_TOGGLE",
  YAW_CLOCKWISE: "YAW_CLOCKWISE",
  YAW_COUNTER_CLOCKWISE: "YAW_COUNTER_CLOCKWISE",
  EDIT_PARAMETER: "EDIT_PARAMETER",
  RESET_EVENT_LOGS: "RESET_EVENT_LOGS",
  RESET_ENERGY_COUNTERS: "RESET_ENERGY_COUNTERS",
  RESET_PEAK_VALUES: "RESET_PEAK_VALUES",
  RESET_POWER_CURVE: "RESET_POWER_CURVE",
  RESTART_CONTROLLER: "RESTART_CONTROLLER",
  UPDATE_VIB_SENSOR: "UPDATE_VIB_SENSOR",
  RESET_DEBUG_DATA: "RESET_DEBUG_DATA",
});

export const CHART_TYPE = Object.freeze({
  Hourly: "Hourly",
  Daily: "Daily",
  Monthly: "Monthly",
});

export const SET_VIEW_MODE = "SET_VIEW_MODE";
export const SET_PAGE_NUMBER = "SET_PAGE_NUMBER";
export const SET_TOTAL_PAGE_NUMBER = "SET_TOTAL_PAGE_NUMBER";
export const ITEMS_PER_PAGE = 12;
export const SET_THEME = "SET_THEME";
export const THEME = "THEME";
export const SET_IS_IDLE = "SET_IS_IDLE";
export const LOAD_WIND_TURBINES = "LOAD_WIND_TURBINES";
export const LOAD_WIND_TURBINE = "LOAD_WIND_TURBINE";
export const TURBINE_LAST_UPDATE = "TURBINE_LAST_UPDATE";
export const LIVE_PARAMS_UPDATE = "LIVE_PARAMS_UPDATE";
export const PARAM_UPDATE = "PARAM_UPDATE";
//export const API_URL = "http://localhost:4000";
//export const SOCKET_URL = "ws://localhost:4000";

//export const API_URL = 'https://orbitalscada.devtelsoftware.com/api';
//export const SOCKET_URL = 'wss://orbitalscada.devtelsoftware.com';

export const API_URL = 'https://oadmins.italscada.com';
export const SOCKET_URL = 'wss://oadmins.italscada.com';
