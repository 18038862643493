import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Temperature from 'components/dashboard/Temperature';
import { loadCurrentWeather } from 'actions/weather';
import {
  getCurrentWeather, isLoadingCurrentWeather, loadCurrentWeatherError,
} from 'selectors/weather';

const mapStateToProps = (state, ownProps) => {
  const coordinates = ownProps.coordinates;
  return {
    data: getCurrentWeather(state, coordinates),
    isLoading: isLoadingCurrentWeather(state, coordinates),
    error: loadCurrentWeatherError(state, coordinates),
  }
};

const TemperatureContainer = ({ coordinates, loadCurrentTemperature, data, ...otherProps }) => {
  useEffect(() => {
    loadCurrentTemperature(coordinates);
  }, [loadCurrentTemperature, coordinates]);

  return (
    <Temperature value={data} {...otherProps} />
  );
};

export default connect(mapStateToProps, {
  loadCurrentTemperature: loadCurrentWeather
})(TemperatureContainer);
