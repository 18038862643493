import React, { useEffect, useState } from 'react';

import ResetPasswordContainer from 'containers/auth/ResetPassword';
import AuthRoute from './AuthRoute';

const ResetPassword = ({ location, history }) => {
  const [token, setToken] = useState(null);

  useEffect(() => {
    const { search = '' } = location;
    if (search.indexOf('?token=') > -1) {
      setToken(search.split('?token=')[1]);
    } else {
      history.push('/');
    }
  }, []);

  if (!token) {
    return null;
  }

  return (
    <AuthRoute>
      <ResetPasswordContainer token={token} history={history} />
    </AuthRoute>
  )
};

export default ResetPassword;
