import React from "react";
import styled from "styled-components";
import { Progress, Text } from "components/shared";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Title = styled((props) => <Text {...props} />)`
  font-weight: 600;
  color: ${(props) => props.theme.textMediumDark};
`;

const Details = styled(Title)`
  font-weight: 700;
  margin-top: 2px;
`;

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProductionDetails = ({
  value,
  maxValue = 100,
  color = "rgb(50,186,146)",
  title,
  details,
  withProgress = true,
}) => {
  /*   if (withProgress) {
    return (
      <DetailsWrapper>
        <Title size={12}>{title}</Title>
        <Details size={16}>{details}</Details>
      </DetailsWrapper>
    );
  }
  return (
    <Wrapper>
      <Title size={12}>{title}</Title>
      <Details size={13} style={{ marginLeft: 16 }}>
        {details}
      </Details>
    </Wrapper>
  ); */

  return (
    <DetailsWrapper>
      <Title size={12}>{title}</Title>
      <Details size={16}>{details}</Details>
    </DetailsWrapper>
  );
};

ProductionDetails.propTypes = {};

ProductionDetails.defaultProps = {};

export default ProductionDetails;
