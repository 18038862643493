import XLSX from "xlsx";
import { groupBy, pick } from "lodash";
import { createRequestTypes } from "actions/requestTypes";

export const LOAD_WIND_TURBINE_PARAMS = createRequestTypes(
  "LOAD_WIND_TURBINE_PARAMS"
);

const sheetData = {};

export const loadWindTurbineParams = (windTurbineId, type) => (dispatch) => {
  if (!type) {
    return;
  }
  dispatch({
    type: LOAD_WIND_TURBINE_PARAMS.REQUEST,
    payload: { windTurbineId, type },
  });

  const { url } = type;

  if (!sheetData.hasOwnProperty(url)) {
    sheetData[url] = [];
  }
  sheetData[url].push(windTurbineId);

  if (sheetData[url].length > 1) {
    return;
  }

  const oReq = new XMLHttpRequest();
  oReq.responseType = "arraybuffer";

  oReq.addEventListener("load", () => {
    const arraybuffer = oReq.response;

    /* convert data to binary string */
    const data = new Uint8Array(arraybuffer);
    const arr = [];
    for (let i = 0; i !== data.length; ++i)
      arr[i] = String.fromCharCode(data[i]);
    const bstr = arr.join("");

    /* Call XLSX */
    const doc = XLSX.read(bstr, {
      type: "binary",
    });

    const sheets = [
      "Parameter",
      "Monitoring",
      "DI",
      "DO",
      "State",
      "Failure",
      "Command",
    ];
    let response = {};

    sheets.forEach((sheetName) => {
      const sheet = doc.Sheets[sheetName];

      const values = XLSX.utils.sheet_to_json(sheet, {
        raw: true,
      });

      const index = values.findIndex((v) => Object.keys(v).length > 3);
      const header = values[index];

      const allValues = values.slice(index + 1).map((p) => {
        const param = {};
        Object.keys(p).forEach((k) => {
          param[header[k].toLowerCase()] = p[k];
        });
        return param;
      });

      if (sheetName === "Monitoring") {
        response = {
          ...response,
          ...pick(groupBy(allValues, "catagori desc"), [
            "grid",
            "revolution",
            "revolutions",
            "vibration",
            "temperature",
            "wind",
            "meter",
            "condition",
            "io",
          ]),
        };
      } else {
        response[sheetName] = allValues;
      }
    });

    let windTurbine = null;
    while ((windTurbine = sheetData[url].pop())) {
      dispatch({
        type: LOAD_WIND_TURBINE_PARAMS.SUCCESS,
        payload: {
          requestParams: { windTurbineId: windTurbine, type },
          response,
        },
      });
    }
  });

  oReq.open("GET", url, true);
  oReq.send();
};
