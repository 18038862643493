import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Text } from 'components/shared';
import windMillImg from '../../../assets/images/windmills-png-3.png';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  height: 200px;
  width: 200px;
  margin-bottom: 32px;
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const EmptyView = ({ imageUrl, style, title }) => {
  return (
    <Wrapper style={style}>
      <ContentWrapper>
        <Image src={imageUrl} />
        <Text size={19}>{title}</Text>
      </ContentWrapper>
    </Wrapper>
  )
};

EmptyView.propTypes = {
  imageUrl: PropTypes.string,
  style: PropTypes.shape({}),
  title: PropTypes.string,
};

EmptyView.defaultProps = {
  imageUrl: windMillImg,
  style: {},
  title: 'No wind turbines found'
};

export default EmptyView;
