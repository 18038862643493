import React from "react";
import { Card } from "semantic-ui-react";
import styled from "styled-components";
import media from "styled-media-query";

const StyledCardContent = styled((props, className) => (
  <Card.Content className={className} {...props} />
))`
  &&& {
    background-color: ${(props) => props.theme.cardBackgroundColor};
    padding: 0rem 2rem 2rem 2rem;

    ${media.lessThan("small")`
    padding: 0rem; 
    `}
  }
`;

export default (props) => <StyledCardContent {...props} />;
