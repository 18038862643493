import React from 'react';
import ControlButtons from 'components/shared/controls/ControlButtons';

const LiveControlButtons = (props) => {
  const handleAfterActionClicked = (action, result) => {
  }

  return (
    <ControlButtons
      windTurbineIdentifier={props.windTurbine.identifier}
      buttons={props.turbineParams ? props.turbineParams.Command.filter(b => b.dbname && b.permission === 0) : []}
      onAfterActionClicked={handleAfterActionClicked}
    />);
};

LiveControlButtons.propTypes = {};

LiveControlButtons.defaultProps = {};

export default LiveControlButtons;
