import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Loader } from "semantic-ui-react";
import { connect } from "react-redux";

import DataGraphComponent from "components/history/DataGraph";
import { getAllMonitoringParameters } from "selectors/turbineParams";

import useAxios from "axios-hooks";

const Wrapper = styled.div`
  position: relative;
`;

const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.1);
`;

const DataGraph = ({ chartType, date, windTurbine, ...props }) => {
  const [historyData, setHistoryData] = useState([]);
  const [detailedHistoryData, setDetailedHistoryData] = useState([]);

  const [{ loading, data }, getHistoryDataHourly] = useAxios(
    {
      url: `/historyData`,
      params: {
        windTurbineId: windTurbine.id,
        chartType: "Hourly",
        date,
      },
    },
    {
      manual: true,
    }
  );

  const [detailedResult, getHistoryDataHourlyDetailed] = useAxios(
    {
      url: `/historyData/historyAvgDataHourlies`,
      params: {
        windTurbineId: windTurbine.id,
        date,
      },
    },
    {
      manual: true,
    }
  );

  const [dailyResult, getHistoryDataDailyDetailed] = useAxios(
    {
      url: `/historyData/historyAvgDataDailies`,
      params: {
        windTurbineId: windTurbine.id,
        date,
      },
    },
    {
      manual: true,
    }
  );

  const [monthlyResult, getHistoryDataMonthlyDetailed] = useAxios(
    {
      url: `/historyData/historyAvgDataMonthlies`,
      params: {
        windTurbineId: windTurbine.id,
        date,
      },
    },
    {
      manual: true,
    }
  );

  useEffect(() => {
    if (chartType !== "Hourly") {
      return;
    }

    if (!loading) {
      if (data) {
        const formatted = data.map((d) => ({
          ...d,
          values: JSON.parse(d.values),
        }));
        setHistoryData(formatted);
      } else {
        getHistoryDataHourly();
      }
    }
  }, [data, date, loading, windTurbine.id]);

  useEffect(() => {
    if (!detailedResult) {
      return;
    }

    if (chartType !== "Hourly") {
      return;
    }

    if (!detailedResult.loading) {
      if (detailedResult.data) {
        const formatted = [];
        detailedResult.data.forEach((historyAvgDataHourly) => {
          historyAvgDataHourly.historyDatas.forEach((hd) => {
            formatted.push({
              ...hd,
              values: JSON.parse(hd.values),
            });
          });
        });
        setDetailedHistoryData(formatted);
      } else {
        getHistoryDataHourlyDetailed();
      }
    }
  }, [detailedResult]);

  useEffect(() => {
    if (chartType !== "Daily") {
      return;
    }

    if (!dailyResult) {
      return;
    }

    if (!dailyResult.loading) {
      if (dailyResult.data) {
        const formatted = [];
        dailyResult.data.forEach((historyAvgDataDaily) => {
          formatted.push({
            ...historyAvgDataDaily,
            values: JSON.parse(historyAvgDataDaily.avgValues),
          });
        });
        setHistoryData(formatted);
      } else {
        getHistoryDataDailyDetailed();
      }
    }
  }, [dailyResult]);

  useEffect(() => {
    if (chartType !== "Monthly") {
      return;
    }

    if (!monthlyResult) {
      return;
    }

    if (!monthlyResult.loading) {
      if (monthlyResult.data) {
        const formatted = [];
        monthlyResult.data.forEach((historyAvgDataMonthly) => {
          formatted.push({
            ...historyAvgDataMonthly,
            values: JSON.parse(historyAvgDataMonthly.avgValues),
          });
        });
        setHistoryData(formatted);
      } else {
        getHistoryDataMonthlyDetailed();
      }
    }
  }, [monthlyResult]);
  const renderLoading = () => (
    <LoadingWrapper>
      <Loader active inline="centered" />
    </LoadingWrapper>
  );

  const isLoading =
    loading ||
    (detailedResult && detailedResult.loading) ||
    (dailyResult && dailyResult.loading) ||
    (monthlyResult && monthlyResult.loading);

  return (
    <Wrapper>
      {!loading && (
        <DataGraphComponent
          data={historyData}
          detailedData={detailedHistoryData}
          chartType={chartType}
          date={date}
          firstMonitoredParameter={props.firstMonitoredParameter}
          secondMonitoredParameter={props.secondMonitoredParameter}
          onfirstParameterChange={props.onFirstParameterChange}
          onsecondParameterChange={props.onSecondParameterChange}
          {...props}
        />
      )}
      {isLoading && renderLoading()}
    </Wrapper>
  );
};

const mapStateToProps = (state, ownProps) => ({
  monitoringParams: getAllMonitoringParameters(state, ownProps.windTurbine.id),
  turbineParams: state.turbineParams[ownProps.windTurbine.id],
});

export default connect(mapStateToProps)(DataGraph);
