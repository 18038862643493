import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Text } from 'components/shared/index';

const Wrapper = styled.div`
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
`;

const Label = styled(props => <Text {...props} />)`
  color: ${props => props.theme.textMedium};
  font-weight: 600;
`;

const Value = styled(Label)`
  margin-left: 40px;
`;

const Image = styled.img`
  width: 50px;
  height: 50px;
`;

const WeatherDayForecast = ({ weather, day, wind, windDirection, dayTemperature, nightTemperature }) => {
  const getWindDirection = (degrees) => {
    if (degrees > 337.5) return 'North';
    if (degrees > 292.5) return 'North West';
    if (degrees > 247.5) return 'West';
    if (degrees > 202.5) return 'South West';
    if (degrees > 157.5) return 'South';
    if (degrees > 122.5) return 'South East';
    if (degrees > 67.5) return 'East';
    if (degrees > 22.5) return 'North East';
    return 'North';
  };

  return (
    <Wrapper>
      <Row>
        <Image src={`https://openweathermap.org/img/w/${weather.icon}.png`} />
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginLeft: 16 }}>
          <Label size={14}>{day}</Label>
          <Label size={12} style={{ marginTop: 2 }}>{weather.main}</Label>
        </div>
      </Row>
      <Row>
        <Label size={12}>Wind</Label>
        <Value size={12}>{`${wind} m/s`}</Value>
      </Row>
      <Row>
        <Label size={12}>Wind direction</Label>
        <Value size={12}>{getWindDirection(windDirection)}</Value>
      </Row>
      <Row>
        <Label size={12}>Day temperature</Label>
        <Value size={12}>{`${dayTemperature} °C`}</Value>
      </Row>
      <Row>
        <Label size={12}>Night temperature</Label>
        <Value size={12}>{`${nightTemperature} °C`}</Value>
      </Row>
    </Wrapper>
  )
};

WeatherDayForecast.propTypes = {
  weather: PropTypes.shape({}).isRequired,
  day: PropTypes.string.isRequired,
  windDirection: PropTypes.number.isRequired,
  wind: PropTypes.number.isRequired,
  dayTemperature: PropTypes.string.isRequired,
  nightTemperature: PropTypes.string.isRequired,
};

export default WeatherDayForecast;
