import React from 'react';
import { Container } from 'semantic-ui-react';

const ContainerComponent = ({ children, ...otherProps }) => (
  <Container {...otherProps}>
    {children}
  </Container>
);

export default ContainerComponent;
