import React from 'react';
import Dashboard from 'containers/dashboard/Dashboard';
import PrivateRoute from 'routes/PrivateRoute';

import { Switch, Route, Redirect } from 'react-router-dom';
import { Licenses, NotFound, ResetPassword, ForgotPassword, Details } from 'components';

const Router = (props) => (
  <Switch>
    <Route exact path='/forgot_password' component={ForgotPassword} />
    <Route exact path='/reset_password' component={ResetPassword} />
    <PrivateRoute exact path='/' component={Dashboard}/>
    <PrivateRoute exact path='/licenses' component={Licenses}/>
    <PrivateRoute exact path='/:id' component={Details}/>
    <Route exact path='/404' component={NotFound} />
    <Route render={() => <Redirect to="/"/>}/>
  </Switch>
);
export default Router;
