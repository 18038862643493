import React, { useContext, useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Table, Menu, Icon, Modal, Input, Image } from "semantic-ui-react";
import styled from "styled-components";
import media from "styled-media-query";
import { ThemeContext } from "styled-components";
import { Button } from "components/shared";

const styles = {
  header: {
    padding: "8px 50px 8px 50px",
  },
};

const CustomTable = styled((props, className) => (
  <Table className={className} {...props} />
))`
  margin: 0rem;
  ${media.lessThan("small")`
    table-layout: fixed;
  `}
}
`;

const CustomCell = styled((props, className) => (
  <Table.Cell className={className} {...props} />
))`
  font-size: 1.1rem;
  word-wrap: break-word;
  width: 25%;
  vertical-align: top;
  border-bottom: 2px solid black;

  ${media.lessThan("small")`
  font-size: 0.8rem;
  padding: 0.5rem !important;
  `}
`;

const LogsNavigator = styled.div`
  display: flex;
  justify-content: flex-end;

  ${media.lessThan("768px")`
  justify-content: center;
  `}
`;

const ParametersTable = ({
  params,
  paginated,
  columnNames,
  columnKeys,
  editable,
  numberOfItemsPerPage,
  cellStyle: cellStyleProp,
  boldIndexes,
  onEditParameter,
}) => {
  const NUMBER_OF_ITEMS = numberOfItemsPerPage || params.length;
  const themeContext = useContext(ThemeContext);
  const [lastItemIndex, setLastItemIndex] = useState(NUMBER_OF_ITEMS - 1);
  const [data, setData] = useState([]);
  const [confirmationVisible, setConfirmationVisible] = useState(false);
  const [editedParameter, setEditedParameter] = useState(null);
  const [editedNewValue, setEditedNewValue] = useState(null);
  const [showImageModal, setShowImageModal] = useState(false);
  const [image, setImage] = useState("");
  const editInputRef = useRef();

  useEffect(() => {
    setDisplayedItems(
      startIndexOfSlice(lastItemIndex, NUMBER_OF_ITEMS),
      lastItemIndex
    );
  }, [params, lastItemIndex, NUMBER_OF_ITEMS, paginated]);

  const startIndexOfSlice = (lastIndex, totalItems) =>
    lastIndex === totalItems - 1 ? 0 : lastIndex - totalItems + 1;

  const setDisplayedItems = (startIndex, lastIndex) => {
    params.length && paginated
      ? setData(params.slice(startIndex, lastIndex + 1))
      : setData(params);
  };

  const cellStyle = {
    ...styles.cell,
    ...cellStyleProp,
    borderColor: themeContext.controlsBackgroundColor,
    color: themeContext.textDark,
  };

  const headerStyle = {
    ...cellStyleProp,
    color: themeContext.textDark,
    minWidth: "1rem",
  };

  const onPreviousPage = () => {
    if (lastItemIndex < NUMBER_OF_ITEMS) {
      return;
    }
    setLastItemIndex(lastItemIndex - NUMBER_OF_ITEMS);
  };

  const onNextPage = () => {
    if (lastItemIndex < params.length && params[lastItemIndex + 1]) {
      setLastItemIndex(lastItemIndex + NUMBER_OF_ITEMS);
    }
  };

  const onEditClick = (param) => {
    setConfirmationVisible(true);
    setEditedNewValue(param.value < 0 ? param.default : param.value);
    setEditedParameter(param);
  };

  const onCancelEditDialog = () => {
    setConfirmationVisible(false);
    setEditedParameter(null);
  };

  const onConfirmEditParam = () => {
    const newValue = editInputRef.current.inputRef.current.valueAsNumber;
    onEditParameter(editedParameter, newValue);
    setConfirmationVisible(false);
    setEditedParameter(null);
  };

  const onEditedNewValueChange = (e, d) => {
    let newVal = parseInt(d.value);
    if (!d.value.length) {
      newVal = undefined;
    }
    setEditedNewValue(newVal);
  };

  const ServiceLogImages = ({ images }) => {
    const logImages = images;

    return (
      <div>
        {logImages.length > 0 &&
          logImages.map((image) => (
            <Icon
              bordered
              inverted
              key={image}
              color="teal"
              style={{ margin: "0px 10px 0px 0px" }}
              name="image"
              onClick={() => {
                setImage(image);
                setShowImageModal(true);
              }}
            />
          ))}
      </div>
    );
  };

  function renderRow(p) {
    const editableCell = typeof editable === "boolean" ? editable : editable(p);
    const cellKeys = columnKeys ? columnKeys : Object.keys(p);
    return (
      <Table.Row key={Object.values(p).join(",")}>
        {cellKeys.map((key, index) => {
          let val;
          if (key == "photo" && p[key] != null) {
            val = (
              <ServiceLogImages images={p[key]} />
            )
          } else {
            if (key == "value" && p[key] < 0) {
              val = p.default;
            } else {
              val = p[key];
            }
          }

          const fontStyle =
            boldIndexes.indexOf(index) > -1 ? { fontWeight: "bold" } : {};
          const alignStyle =
            Object.keys(p).length === 2 && index === 1
              ? { textAlign: "right" }
              : {};
          return (
            <CustomCell
              collapsing={false}
              key={key}
              style={{
                ...cellStyle,
                ...alignStyle,
                ...fontStyle,
                ...headerStyle,
              }}
            >
              {val}
            </CustomCell>
          );
        })}
        {editableCell && (
          <Table.Cell style={{ paddingRight: 0 }}>
            <Button
              icon
              secondary
              style={{ backgroundcolor: "transparent" }}
              onClick={() => onEditClick(p)}
            >
              <Icon name="pencil" />
              <span style={{ marginLeft: 6 }}>Edit</span>
            </Button>
          </Table.Cell>
        )}
      </Table.Row>
    );
  }
  return (
    <>
      <CustomTable columns={4} basic="very" unstackable={true}>
        <Table.Header className="mobile hidden">
          <Table.Row>
            {columnNames &&
              columnNames.map((c) => (
                <Table.HeaderCell key={c} style={headerStyle}>
                  {c}
                </Table.HeaderCell>
              ))}
          </Table.Row>
        </Table.Header>

        <Table.Body
          style={{ backgroundcolor: themeContext.cardBackgroundColor }}
        >
          {data.map(renderRow)}
        </Table.Body>

        {paginated && (
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan="5">
                <LogsNavigator>
                  <Menu
                    floated="right"
                    pagination
                    style={{
                      backgroundColor: `${themeContext.primaryButtonColor}`,
                      marginTop: 16,
                      border: 0,
                      borderShadow: 0,
                      boxShadow: 0,
                    }}
                  >
                    <Menu.Item as="a" icon onClick={onPreviousPage}>
                      <Icon
                        name="chevron left"
                        style={{
                          color:
                            lastItemIndex <= NUMBER_OF_ITEMS
                              ? themeContext.primaryInactiveButtonColor
                              : themeContext.primaryButtonText,
                        }}
                      />
                    </Menu.Item>
                    <Menu.Item as="a" icon onClick={onNextPage}>
                      <Icon
                        name="chevron right"
                        style={{
                          color:
                            params[lastItemIndex + 1] === undefined
                              ? themeContext.primaryInactiveButtonColor
                              : themeContext.primaryButtonText,
                        }}
                      />
                    </Menu.Item>
                  </Menu>
                </LogsNavigator>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        )}
      </CustomTable>

      <Modal
        size="tiny"
        open={confirmationVisible}
        onClose={onCancelEditDialog}
      >
        <Modal.Header>{`Edit "${
          editedParameter && editedParameter.name
        }"`}</Modal.Header>
        <Modal.Content>
          {editedParameter && (
            <>
              {editedParameter.number !== undefined && (
                <p>
                  <b>Parameter value: </b>
                  {editedParameter.value < 0 ? editedParameter.default : editedParameter.value}
                </p>
              )}
              {editedParameter.default !== undefined && (
                <p>
                  <b>Default value: </b>
                  {editedParameter.default}
                </p>
              )}
              {editedParameter.min !== undefined && (
                <p>
                  <b>Min: </b>
                  {editedParameter.min}
                </p>
              )}
              {editedParameter.max !== undefined && (
                <p>
                  <b>Max: </b>
                  {editedParameter.max}
                </p>
              )}
              {editedParameter.number !== undefined && (
                <p>
                  <b>Parameter number: </b>
                  {editedParameter.number}
                </p>
              )}
              <Input
                ref={editInputRef}
                defaultValue={editedParameter.value < 0 ? editedParameter.default : editedParameter.value}
                type="number"
                label={{ basic: true, content: `${editedParameter.unit}` }}
                labelPosition="right"
                onChange={onEditedNewValueChange}
              />
            </>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={onCancelEditDialog}>
            Cancel
          </Button>
          <Button
            disabled={
              editedNewValue === undefined ||
              (editedParameter &&
                (editedNewValue < editedParameter.min ||
                  editedNewValue > editedParameter.max))
            }
            positive
            icon="checkmark"
            labelPosition="right"
            content="Save"
            style={{ backgroundcolor: "red" }}
            onClick={onConfirmEditParam}
          />
        </Modal.Actions>
      </Modal>

      <Modal
        size={"small"}
        open={showImageModal}
        onClose={() => setShowImageModal(false)}
      >
        <Modal.Content>
          <Image src={image} />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setShowImageModal(false)} positive>
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

ParametersTable.propTypes = {
  params: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  paginated: PropTypes.bool,
  columnNames: PropTypes.arrayOf(PropTypes.string),
  columnKeys: PropTypes.arrayOf(PropTypes.string),
  editable: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  numberOfItemsPerPage: PropTypes.number,
  cellStyle: PropTypes.shape({}),
  boldIndexes: PropTypes.arrayOf(PropTypes.number),
  onEditParameter: PropTypes.func,
};

ParametersTable.defaultProps = {
  paginated: false,
  columnNames: [],
  editable: false,
  numberOfItemsPerPage: null,
  cellStyle: {},
  boldIndexes: [],
  onEditParameter: () => {},
};

export default React.memo(ParametersTable);
