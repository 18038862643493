import React, { useEffect, useState } from "react";
import { Checkbox, Button, Text } from "components/shared/index";
import { Form } from "semantic-ui-react";
import { withRouter } from "react-router-dom";

import AuthForm from "./AuthForm";
import InputField from "../InputField";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ThemeToggle } from "components";
import LoginValidation from "classes/LoginValidation";

const FormWrapper = styled.div`
  width: 100%;
  padding: 2rem;
`;

const KeepMeSignedIn = styled((props) => <Text {...props} />)`
  margin-left: 8px;
  color: ${(props) => props.theme.textDark};
`;

const styles = {
  formElement: {
    width: "100%",
    wordBreak: "break-all",
  },
  textFormElement: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    wordBreak: "break-all",
  },
};

const LoginForm = ({ history, onSubmitLogin, isLoading }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberLogin, setRememberLogin] = useState(false);
  const [validCredentials, setValidCredentials] = useState(true);

  let credentialsValidator = new LoginValidation();

  const onUsernameChange = (ev, { value }) => {
    setUsername(value);
  };

  const onPasswordChange = (ev, { value }) => {
    setPassword(value);
  };

  const onSubmit = () => {
    return (
      validateCredentials(username, password) &&
      onSubmitLogin(username, password, rememberLogin)
    );
  };

  const onRememberLoginChange = (ev, { checked }) => {
    setRememberLogin(checked);
  };

  const validateCredentials = (username, password) => {
    let validationResult = credentialsValidator.validateCredentials(
      username,
      password
    );

    if (validationResult) return true;

    setValidCredentials(false);

    return false;
  };

  useEffect(() => {
    setTimeout(() => {
      if (validCredentials === false) {
        setValidCredentials(true);
      }
    }, 2000);
  }, [validCredentials, setValidCredentials]);

  const onForgotPasswordPress = () => {
    setUsername("");
    setPassword("");
    setRememberLogin(false);
    history.push("/forgot_password");
  };

  return (
    <AuthForm>
      <FormWrapper onSubmit={onSubmit}>
        <Form>
          <Form.Field style={{ marginBottom: "2rem" }}>
            <InputField
              value={username}
              style={styles.textFormElement}
              onChange={onUsernameChange}
            />
          </Form.Field>
          <Form.Field style={{ marginBottom: "2rem" }}>
            <InputField
              value={password}
              type="password"
              secureText
              style={styles.textFormElement}
              onChange={onPasswordChange}
            />
          </Form.Field>
          <Form.Field style={{ display: "flex", marginBottom: "2rem" }}>
            <Checkbox
              toggle
              checked={rememberLogin}
              onChange={onRememberLoginChange}
            />
            <KeepMeSignedIn size={14}>Keep me signed in</KeepMeSignedIn>
          </Form.Field>
          <Form.Field style={{ marginBottom: "2rem" }}>
            <Button
              type="submit"
              size="huge"
              style={styles.formElement}
              onSubmit={onSubmit}
              loading={isLoading}
            >
              Log in
            </Button>
          </Form.Field>
          <Form.Field style={{ marginBottom: "2rem" }}>
            <Button
              secondary
              style={styles.formElement}
              onClick={onForgotPasswordPress}
            >
              Forgot password?
            </Button>
          </Form.Field>
          <Form.Field style={{ marginBottom: "2rem" }}>
            {validCredentials === false ? (
              <Text>Invalid credentials. Please try again</Text>
            ) : (
              <></>
            )}
          </Form.Field>
          <Form.Field>
            <ThemeToggle></ThemeToggle>
          </Form.Field>
        </Form>
      </FormWrapper>
    </AuthForm>
  );
};

LoginForm.propTypes = {
  onSubmitLogin: PropTypes.func,
};

LoginForm.defaultProps = {
  login: () => {},
};

export default withRouter(LoginForm);
