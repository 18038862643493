import { keyBy } from 'lodash';

import { LOAD_WIND_TURBINES, LOAD_WIND_TURBINE } from "../constants";

const initialState = {
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_WIND_TURBINES:
      return {
        ...state,
        ...keyBy(action.payload, 'id'),
      };
    case LOAD_WIND_TURBINE:
      return {
        // ...state,
        ...action.payload,
      };

    default: return state;
  }
}
