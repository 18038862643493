import { shouldDisplayParam } from 'utils/params';

export const getAllMonitoringParameters = (state, windTurbineId) => {
  const slice = state.turbineParams[windTurbineId];
  if (!slice) {
    return [];
  }

  const monitoringState = state.turbineParams[windTurbineId];
  let monitoringParams = [];
  if (monitoringState) {
    monitoringParams = [
      ...monitoringState.grid,
      ...monitoringState.revolution,
      ...monitoringState.revolutions,
      ...monitoringState.temperature,
      ...monitoringState.vibration,
      ...monitoringState.wind,
      ...monitoringState.condition,
      ...monitoringState.meter,
    ];
  }

  return monitoringParams.filter(shouldDisplayParam);
};

export const getTurbineParams = (state, windTurbineId) => {
  const turbineParamsState = state.turbineParams[windTurbineId];
  if (!turbineParamsState || !turbineParamsState.Parameter) {
    return [];
  }

  const windTurbine = state.windTurbines[windTurbineId];

  return turbineParamsState.Parameter.map(p => {
    const value = windTurbine && windTurbine.params
      ? windTurbine.params[p.dbname]
      : 'N/A';
    return {
      dbname: p.dbname,
      number: p.number,
      name: p.text,
      default: p.default,
      value,
      min: p.min,
      max: p.max,
      unit: p.unit,
      scale: p.scale
    };
  });
};
