import React from 'react';
import styled from 'styled-components';
import { Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const StyledButton = styled(Button)`
&&& {
  background-color: ${props => props.secondary ? props.theme.primaryButtonColor + "!important" : props.theme.accentColor + "!important"}; 
  
  @media (hover: hover) {
    :hover {
       background-color: ${props => props.secondary ? props.theme.primaryButtonColor + "!important" : props.theme.accentColor + "!important"};
     }
  }
  
  :active {
  background-color: ${props => props.secondary ? props.theme.primaryButtonColor + "!important" : props.theme.accentColor + "!important"};
  }
}
`;

const ButtonComponent = ({ children, ...otherProps }) => {
  return (
    <StyledButton {...otherProps}>
      {children}
    </StyledButton>
  )
};

ButtonComponent.propTypes = {
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  loading: PropTypes.bool,
};

ButtonComponent.defaultProps = {
  primary: true,
  secondary: false,
  loading: false,
};

export default ButtonComponent;
