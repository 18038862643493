import React from 'react';
import styled from 'styled-components';
import { Map } from 'pigeon-maps';
import Overlay from 'pigeon-overlay';
import { withRouter } from 'react-router-dom';


const TurbineMarker = styled.div`
  background-color: ${props => props.theme.accentColor};
  padding: 4px 8px;
  border-radius: 5px;
  color: ${props => props.theme.textLight};
  
  &:hover {
    cursor: pointer;
  }
`;

const distance = (lat1, lon1, lat2, lon2, unit = 'K') => {
  let radlat1 = Math.PI * lat1 / 180;
  let radlat2 = Math.PI * lat2 / 180;
  let theta = lon1 - lon2;
  let radtheta = Math.PI * theta / 180;
  let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  dist = Math.acos(dist);
  dist = dist * 180 / Math.PI;
  dist = dist * 60 * 1.1515;
  if (unit === "K") {
    dist = dist * 1.609344;
  }
  if (unit === "N") {
    dist = dist * 0.8684;
  }
  return dist;
};

const WindTurbinesMap = ({ history, data, isLoading }) => {
  const handleMarkerClick = (turbine) => {
    history.push(`/${turbine.id}`);
  };

  const getMapDetails = () => {
    let maxN = 0;
    let maxS = 0;
    let maxE = 0;
    let maxW = 0;

    data.forEach((d) => {
      const latitude = parseFloat(d.latitude);
      const longitude = parseFloat(d.longitude);
      if (latitude >= maxN || maxN === 0) {
        maxN = latitude;
      }
      if (latitude <= maxS || maxS === 0) {
        maxS = latitude;
      }
      if (longitude >= maxE || maxE === 0) {
        maxE = longitude;
      }
      if (longitude <= maxW || maxW === 0) {
        maxW = longitude;
      }
    });

    // const dist = distance(maxN, maxE, maxS, maxW);

    let zoom = 5;

    // TODO: calculate zoom based on dist

    return { bounds: [(maxN + maxS) / 2, (maxE + maxW) / 2], zoom };
  };

  const mapDetails = getMapDetails();

  return (
    <div style={{ width: '100%', height: '800px' }}>
      <Map center={mapDetails.bounds} zoom={mapDetails.zoom}>
        {data.map(d => (
          <Overlay anchor={[parseFloat(d.latitude), parseFloat(d.longitude)]} key={d.id}>
            <TurbineMarker onClick={() => handleMarkerClick(d)}>{d.name}</TurbineMarker>
          </Overlay>
        ))}
      </Map>
    </div>
  );
};

export default withRouter(WindTurbinesMap);
