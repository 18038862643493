import React from 'react';

import ForgotPasswordContainer from 'containers/auth/ForgotPassword';
import AuthRoute from './AuthRoute';

const ForgotPassword = (props) => {
  return (
    <AuthRoute>
      <ForgotPasswordContainer {...props} />
    </AuthRoute>
  )
};

export default ForgotPassword;
