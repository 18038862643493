import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import { sessionService } from "redux-react-session";
import { persistStore, persistReducer } from "redux-persist";

import rootReducer from "./reducers";
import { REMEMBER_LOGIN } from "./constants";
import storage from "redux-persist/lib/storage";

const validateSession = async (session) => {
  return JSON.parse(localStorage.getItem(REMEMBER_LOGIN));
};

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["weather"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default function configureStore() {
  const middlewares = [thunk];

  if (process.env.NODE_ENV === "development") {
    // middlewares.push(logger);
  }

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
    persistedReducer,
    composeEnhancers(
    applyMiddleware(...middlewares))
  );

  const persistor = persistStore(store);

  const options = { redirectPath: "/", validateSession };
  sessionService.initSessionService(store, options);

  return { store, persistor };
}
