import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Loader } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import useAxios from 'axios-hooks'; 

import ActionLogs from 'components/advanced/ActionLogs';


const convertLogToParam = l => ({
  user: l.user.name, action: l.action, details: '', date: moment(l.updatedAt).format('D MMMM YYYY, HH:mm:ss')
});

const ActionLogsContainer = ({ match }) => {
  const windTurbineId = match.params.id;

  const [logs, setLogs] = useState([]);

  const [{ data, loading }] = useAxios({
    url: `/actionLogs/${windTurbineId}`,
    method: "GET",
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    if (data) {
      const actionLogs = data || [];
      setLogs(actionLogs.map(convertLogToParam));
    }
  }, [data]);

  return (
    <>
      <Loader active={loading} inline='centered' />
      <ActionLogs
        logs={logs}
      />
    </>
  );
};

export default withRouter(ActionLogsContainer);
