const getStateLocalized = (errorCode) => {
  let code = '';

  switch (errorCode) {
    case 256:
    case 257:
      code = 'System startup';
      break;
    case 258:
      code = 'System ready';
      break;
    case 512:
    case 513:
      code = 'Restart Reset';
      break;
    case 514:
    case 515:
      code = 'Restart Check';
      break;
    case 516:
      code = 'Restart Fail->Retry';
      break;
    case 517:
      code = 'Restart OK->Restart';
      break;
    case 518:
      code = 'Restart Fail';
      break;
    case 768:
    case 773:
      code = 'Grid Initializing';
      break;
    case 769:
    case 774:
      code = 'Wind is too high';
      break;
    case 770:
      code = 'Expecting MAN reset';
      break;
    case 771:
    case 776:
    case 1024:
    case 1536:
      code = 'Ready';
      break;
    case 772:
      code = 'Autostartup disabled';
      break;
    case 775:
      code = 'Wind is too low';
      break;
    case 1025:
    case 1026:
    case 1027:
    case 1028:
      code = 'Standby';
      break;
    case 1280:
    case 1281:
      code = 'Synchronizing';
      break;
    case 1282:
    case 1285:
      code = 'Connected';
      break;
    case 1283:
    case 1284:
      code = 'Synchronizing failure';
      break;
    case 1537:
    case 1538:
    case 1539:
    case 1540:
    case 1541:
      code = 'Releasing brake';
      break;
    case 1792:
    case 1793:
    case 1794:
    case 1795:
      code = 'Ramp start';
      break;
    case 1796:
      code = 'Production';
      break;
    case 1797:
      code = 'Production low';
      break;
    case 1798:
    case 1799:
    case 1800:
    case 1801:
    case 1802:
      code = 'Ramp stop';
      break;
    case 2048:
    case 2049:
    case 2050:
    case 2051:
    case 2052:
    case 2053:
      code = 'Braking Pitch';
      break;
    case 2054:
    case 2055:
    case 2056:
    case 2057:
      code = 'Braking Pitch/Shaft';
      break;
    case 2058:
    case 2059:
    case 2060:
      code = 'Braking Pitch Emergency';
      break;
    case 2061:
    case 2062:
    case 2063:
    case 2304:
      code = 'Stopped';
      break;
    case 2560:
    case 2561:
    case 2562:
    case 2563:
      code = 'Untwist';
      break;
    case 564:
      code = 'Untwist Error';
      break;

    default: break;
  }

  return code;
};

export {
  getStateLocalized,
};
