import React from "react";

import { ControlButtons } from "components/shared";

const AdvancedControlButtons = (props) => {
  function handleAfterActionClicked() {}

  return (
    <ControlButtons
      windTurbineIdentifier={props.windTurbine.identifier}
      buttons={
        props.turbineParams
          ? props.turbineParams.Command.filter(
              (b) => b.dbname && b.permission === 1
            )
          : []
      }
      onAfterActionClicked={handleAfterActionClicked}
    ></ControlButtons>
  );
};

AdvancedControlButtons.propTypes = {};

AdvancedControlButtons.defaultProps = {};

export default AdvancedControlButtons;
