import { LOAD_WIND_TURBINE_PARAMS } from "actions/details";

const initialState = {
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_WIND_TURBINE_PARAMS.SUCCESS:
      return {
        ...state,
        [action.payload.requestParams.windTurbineId]: action.payload.response,
      };

    default: return state;
  }
}
