import React from "react";
import { Bar, Chart } from "react-chartjs-2";
import "chartjs-plugin-zoom";

class BarChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = { currentGraphState: this.props };
    this.currentGraphReference = React.createRef();
  }

  componentDidMount() {
    this.setState({ currentGraphState: this.props });
  }

  componentDidUpdate() {
    if (this.props !== this.state.currentGraphState) {
      if (!this.props.isZooming) {
        this.currentGraphReference.current.chartInstance.resetZoom();
      }

      this.setState({ currentGraphState: this.props });
    }
  }

  render() {
    return (
      <Bar ref={this.currentGraphReference} {...this.state.currentGraphState} />
    );
  }
}

export default BarChart;
