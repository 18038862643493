import { combineReducers } from 'redux';
import { sessionReducer } from 'redux-react-session';

import settings from './settings';
import weather from './weather';
import dashboard from './dashboard';
import turbineParams from './turbineParams';
import liveParams from './liveParams';
import windTurbines from './windTurbines';
import turbineState from './turbineState';

export default combineReducers({
  settings,
  weather,
  dashboard,
  turbineParams,
  liveParams,
  windTurbines,
  turbineState,
  session: sessionReducer,
});
