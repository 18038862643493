import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ChartComponent, { Chart } from 'react-chartjs-2';
import 'chartjs-plugin-streaming';
import { Dropdown } from 'semantic-ui-react';
import { sortByKey } from "utils/shared";
import styled, { ThemeContext } from 'styled-components';
import theme from 'theme';

const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const LineControlsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
`;

const DropdownWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const DropdownIndicator = styled.div`
  height: 14px;
  width: 14px;
  border-radius: 7px;
  background-color: ${props => props.color};
  margin-right: 8px;
  margin-bottom: 1px;
`;

const config = ({ onRefresh, theme, firstDatasetLabel, secondDatasetLabel }) => ({
  type: 'line',
  data: {
    datasets: [{
      backgroundcolor: theme.graphAxle1,
      borderColor: theme.graphAxle1,
      fill: false,
      cubicInterpolationMode: 'monotone',
      data: [],
      pointBackgroundColor: 'transparent',
      pointBorderColor: 'transparent',
      pointHoverBackgroundColor: theme.graphAxle1,
      pointHoverBorderColor: '#fff',
      pointRadius: 0,
      pointBorderWidth: 0,
      pointHoverBorderWidth: 2,
      pointHoverRadius: 4,
      borderWidth: 2,
      label: firstDatasetLabel,
      yAxisID: 'y-axis-1'
    }, {
      backgroundcolor: theme.graphAxle2,
      borderColor: theme.graphAxle2,
      fill: false,
      cubicInterpolationMode: 'monotone',
      data: [],
      pointBackgroundColor: 'transparent',
      pointHoverBackgroundColor: theme.graphAxle2,
      pointHoverBorderColor: '#fff',
      pointBorderColor: 'transparent',
      pointRadius: 0,
      pointBorderWidth: 0,
      pointHoverBorderWidth: 2,
      pointHoverRadius: 4,
      borderWidth: 2,
      label: secondDatasetLabel,
      yAxisID: 'y-axis-2'
    }]
  },
  options: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    scales: {
      xAxes: [{
        type: 'realtime',
        offset: true,
        realtime: {
          duration: 60000,
          refresh: 1000,
          delay: 5000,
          onRefresh: onRefresh,
        },
        gridLines: {
          display: false,
        },
        ticks: {
          fontColor: theme.textMedium
        },
      }],
      yAxes: [{
        id: 'y-axis-1',
        position: 'left',
        gridLines: {
          drawBorder: false,
          color: theme.shadowColor,
          zeroLineColor: theme.shadowColor,
        },
        ticks: {
          fontColor: theme.textMedium
        },
      }, {
        id: 'y-axis-2',
        position: 'right',
        gridLines: {
          drawBorder: false,
          color: theme.shadowColor,
          zeroLineColor: theme.shadowColor,
        },
        ticks: {
          fontColor: theme.textMedium
        },
      }],
    },
    tooltips: {
      mode: 'index',
      intersect: false,
      backgroundcolor: 'rgba(0, 0, 0, 0.5)',
      bodySpacing: 4,
      callbacks: {
        label: function (tooltipItem, data) {
          var label = data.datasets[tooltipItem.datasetIndex].label || '';

          if (label) {
            label += ': ';
          }
          label += `${tooltipItem.yLabel} ${data.datasets[tooltipItem.datasetIndex].unit || ""}`;
          return label;
        }
      }
    },
    hover: {
      mode: 'index',
      intersect: false
    }
  }
});

Chart.defaults.LineWithLine = Chart.defaults.line;
Chart.controllers.LineWithLine = Chart.controllers.line.extend({
  draw: function (ease) {
    if (this.chart.tooltip._active && this.chart.tooltip._active.length) {
      var activePoint = this.chart.tooltip._active[0],
        ctx = this.chart.ctx,
        x = activePoint.tooltipPosition().x,
        topY = this.chart.scales['y-axis-1'].top,
        bottomY = this.chart.scales['y-axis-1'].bottom;

      // draw line on hover
      ctx.save();
      ctx.beginPath();
      ctx.moveTo(x, topY);
      ctx.lineTo(x, bottomY);
      ctx.lineWidth = 1;
      ctx.strokeStyle = 'rgb(206,206,206)';
      ctx.stroke();
      ctx.restore();
    }
    Chart.controllers.line.prototype.draw.call(this, ease);
  }
});


class LiveDataGraph extends React.PureComponent {
  constructor(props) {
    super(props);

    const firstParam = props.monitoringParams.find(p => p.dbname === 'power');
    const secondParam = props.monitoringParams.find(p => p.dbname === 'wind');

    const conf = config({
      onRefresh: this.onRefresh,
      theme: props.themeContext,
      firstDatasetLabel: firstParam.name,
      secondDatasetLabel: secondParam.name,
    });

    conf.data.datasets[0].unit = firstParam.unit;
    conf.data.datasets[1].unit = secondParam.unit;

    this.state = {
      [firstParam.name]: {},
      [secondParam.name]: {},
      firstParam: "power",
      secondParam: "wind",
      firstParamData: {},
      secondParamData: {},
      firstParamSpec: {},
      secondParamSpec: {},
      cfg: conf
    };
  }

  componentDidUpdate(nextProps) {
    const { firstParam, secondParam } = this.state;

    if (!this.props.liveProps !== nextProps.liveProps) {
      const param1 = this.props.monitoringParams.find(m => m.dbname === firstParam);
      const param2 = this.props.monitoringParams.find(m => m.dbname === secondParam);

      this.setState({
        firstParamSpec: param1,
        secondParamSpec: param2,
        firstParamData: nextProps.liveProps[firstParam.toLowerCase()],
        secondParamData: nextProps.liveProps[secondParam.toLowerCase()],
      });
    }
  }

  setGraphDatasetLabelAndUnit = (datasetIndex, label, unit) => {
    const { firstParamSpec, secondParamSpec, cfg } = this.state;
    const firstDatasetLabel = datasetIndex === 0 ? label : firstParamSpec.name;
    const secondDatasetLabel = datasetIndex === 1 ? label : secondParamSpec.name;

    const conf = config({
      onRefresh: this.onRefresh,
      theme: theme.light,
      firstDatasetLabel,
      secondDatasetLabel,
    });
    conf.data.datasets[0].unit = datasetIndex === 0 ? unit : cfg.data.datasets[0].unit;
    conf.data.datasets[1].unit = datasetIndex === 1 ? unit : cfg.data.datasets[1].unit;

    this.setState({ cfg: conf });
  };

  onLeftItemChange = (ev, { text }) => {
    const newFirstParam = this.props.monitoringParams.find(m => m.name === text);

    this.setState({
      firstParam: newFirstParam.dbname,
      firstParamSpec: newFirstParam,
      firstParamData: this.props.liveProps[newFirstParam.dbname.toLowerCase()]
    });
    this.setGraphDatasetLabelAndUnit(0, text, newFirstParam.unit || "");
  };

  onRightItemChange = (ev, { text }) => {
    const newSecondParam = this.props.monitoringParams.find(m => m.name === text);

    this.setState({
      secondParam: newSecondParam.dbname,
      secondParamSpec: newSecondParam,
      secondParamData: this.props.liveProps[newSecondParam.dbname.toLowerCase()]
    });
    this.setGraphDatasetLabelAndUnit(1, text, newSecondParam.unit);
  };

  onRefresh = (chart) => {
    const { firstParamData, secondParamData } = this.state;

    chart.config.data.datasets.forEach((dataset, index) => {
      const newDataEntry = (index === 0) ? firstParamData : secondParamData;

      if (newDataEntry) {
        const lastEntry = dataset.data[dataset.data.length - 1];
        const shouldUpdate = dataset.data.findIndex(d => d.x === newDataEntry.timestamp) < 0 && (!lastEntry || !lastEntry.x || newDataEntry.timestamp > lastEntry.x + 100);

        if (shouldUpdate) {
          dataset.data.push({
            x: newDataEntry.timestamp,
            y: newDataEntry.value
          });
        } else {
          if (lastEntry) {
            dataset.data.push({
              x: lastEntry.x + 1000,
              y: lastEntry.y,
            })
          }
        }
      }
    });
    chart.update();
  };

  render() {
    const { height, themeContext, monitoringParams } = this.props;
    const { cfg, firstParamSpec, secondParamSpec } = this.state;
    const unsortedOptions = monitoringParams.filter(m => m.name !== firstParamSpec.name && m.name !== secondParamSpec.name);
    const validOptions = sortByKey(unsortedOptions, "name");
    return (
      <ChartWrapper>
        <ChartComponent
          key={`${firstParamSpec.name}${secondParamSpec.name}`}
          data={cfg.data}
          options={cfg.options}
          type="LineWithLine"
        />
        <LineControlsWrapper>
          <DropdownWrapper>
            <DropdownIndicator color={themeContext.graphAxle1} />
            <Dropdown text={firstParamSpec.name} style={{ color: themeContext.textDark }} scrolling>
              <Dropdown.Menu>
                {validOptions.map(i => (
                  <Dropdown.Item key={i.name} text={i.name} onClick={this.onLeftItemChange} />
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </DropdownWrapper>

          <DropdownWrapper>
            <DropdownIndicator color={themeContext.graphAxle2} />
            <Dropdown text={secondParamSpec.name} style={{ color: themeContext.textDark }} scrolling>
              <Dropdown.Menu>
                {validOptions.map(i => (
                  <Dropdown.Item key={`right:${i.name}`} text={i.name} onClick={this.onRightItemChange} />
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </DropdownWrapper>
        </LineControlsWrapper>
      </ChartWrapper>
    );
  }
}

const GraphWrapper = (props) => {
  const themeContext = useContext(ThemeContext);
  if (!props.monitoringParams.length) {
    return null;
  }

  return (
    <LiveDataGraph
      {...props}
      themeContext={themeContext}
    />
  )
};

LiveDataGraph.propTypes = {
  height: PropTypes.number,
};

LiveDataGraph.defaultProps = {
  height: 70,
};

export default React.memo(GraphWrapper);
