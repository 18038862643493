import React from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';

import {toggleTheme as toggleThemeAction} from 'actions/settings';
import {Checkbox} from 'components/shared';

const Wrapper = styled.div`
display: grid;
grid-template-columns: minmax(min-content, min-content) minmax(min-content, min-content);
align-items: center;
`;

const Label = styled.span`
padding: 0.5rem;
  color: ${props => props.theme.textDark};
  font-weight: 600;
`;

const ThemeToggle = ({themeName, toggleTheme, }) => {
  const onThemeChange = () => {
    toggleTheme();
  };

  const isDarkTheme = themeName === 'dark';

  return (
    <Wrapper>
      <Checkbox toggle checked={isDarkTheme} onChange={onThemeChange} />
      <Label>{isDarkTheme ? 'Dark mode' : 'Light mode'}</Label>
    </Wrapper>
  );
};

ThemeToggle.propTypes = {};

ThemeToggle.defaultProps = {};

const mapStateToProps = state => ({
  themeName: state.settings.theme,
});

export default connect(mapStateToProps, {
  toggleTheme: toggleThemeAction
})(ThemeToggle);
