const light = {
  primaryColor: "#26BC7E",
  secondaryColor: "#1150FE",
  accentColor: "#3ab9ae",
  backgroundcolor: "#fcfbfa",
  headerBackgroundColor: "#fff",
  shadowColor: "#e7e7e7",
  cardBackgroundColor: "#fff",
  controlsBackgroundColor: "#f7f7f7",

  // text
  textDark: "#44464B",
  textMediumDark: "#63656a",
  textMedium: "#7B7E81",
  textMediumLight: "#B7B8BA",
  textLight: "#fff",

  // common
  graphAxle1: "#FFB419",
  graphAxle2: "#8B3AB9",

  //circle graph
  circleBackground: "#D9D9D9",

  //button
  primaryButtonColor: "#D9D9D9",
  primaryInactiveButtonColor: "#ABABAB",
  primaryButtonText: "#3A3A3A",
};

const dark = {
  primaryColor: "#26BC7E",
  secondaryColor: "#1150FE",
  accentColor: "#31AC9E",
  backgroundcolor: "#454545",
  headerBackgroundColor: "#34363b",
  shadowColor: "#333333",
  cardBackgroundColor: "#505154",
  controlsBackgroundColor: "#4a4b4e",

  // text
  textDark: "#c8d4d3",
  textMediumDark: "#c1cdcc",
  textMedium: "#b4bfbe",
  textMediumLight: "#B7B8BA",
  textLight: "#fff",

  // common
  graphAxle1: "#FFB419",
  graphAxle2: "#8B3AB9",

  //circle graph
  circleBackground: "#878787",

  //button
  primaryButtonColor: "#6d6d73",
  primaryInactiveButtonColor: "#767675",
  primaryButtonText: "#E3E3E3",
};

export default {
  light,
  dark,
};
