import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { Text } from 'components/shared';

const Wrapper = styled.div`
  height: 24px;
  width: 24px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.color};
`;

const Title = styled(props => <Text {...props} />)`
  font-weight: 600;
  color: ${props => props.theme.textLight};
`;

// const MODES = ['A', 'M', 'P'];

const WindTurbineMode = ({ mode, ...props }) => {
  const themeContext = useContext(ThemeContext);

  let color = themeContext.accentColor;

  if (mode === 'M') {
    color = themeContext.textMedium;
  }
  if (mode === 'P') {
    color = themeContext.textDark;
  }

  return (
    <Wrapper color={color} {...props}>
      <Title size={13}>{mode}</Title>
    </Wrapper>
  )
};

WindTurbineMode.propTypes = {};

WindTurbineMode.defaultProps = {};

export default WindTurbineMode;
