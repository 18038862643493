import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { Icon, Text } from "components/shared";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto 2fr;
  column-gap: 1rem;
  padding: 0.5rem;
`;

const DetailsWrapper = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  justify-items: start;
`;

const StyledIcon = styled(Icon)`
  &&& {
    color: ${(props) => props.theme.textDark};
  }
`;

const Title = styled((props) => <Text {...props} />)`
  color: ${(props) => props.theme.textMedium};
  font-weight: 700;
`;

const Subtitle = styled(Title)`
  color: ${(props) =>
    props.isButton ? props.theme.accentColor : props.theme.textDark};
  margin-top: 2px;

  ${(props) =>
    props.isButton &&
    `
      &:hover {
        cursor: pointer;
      }
    `}
`;

const Info = ({ icon, title, subtitle, subtitleColor, onClick }) => {
  return (
    <Wrapper>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <StyledIcon name={icon} size="big" />
      </div>
      <DetailsWrapper>
        <Title size={11}>{title}</Title>
        <Subtitle isButton={onClick} size={17} onClick={onClick}>
          {subtitle}
        </Subtitle>
      </DetailsWrapper>
    </Wrapper>
  );
};

Info.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  subtitleColor: PropTypes.string,
  onClick: PropTypes.func,
};

Info.defaultProps = {
  subtitleColor: null,
  onClick: () => {},
};

export default Info;
