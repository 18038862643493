import React from 'react';
import PropTypes from 'prop-types';
import LargeScreenHeader from './LargeScreenHeader';
import MobileHeader from './MobileHeader';

const AppHeader = () => (
  <>
    <LargeScreenHeader visibility={`mobile hidden`} />
    <MobileHeader visibility={`mobile only`} />
  </>
);

AppHeader.propTypes = {
  logout: PropTypes.func,
};

AppHeader.defaultProps = {
  logout: () => {},
};

export default AppHeader;
