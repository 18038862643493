
import { configure } from 'axios-hooks'
import Axios from 'axios'
import { API_URL } from '../constants';

const axios = Axios.create({
  baseURL: API_URL
})

axios.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("token");

    if (token) {
      config.headers = {
        authorization: `Bearer ${token}`
      };
    }
    return config;
  },
  (error) => Promise.reject(error)
);

configure({ axios })