import React from 'react';
import PropTypes from 'prop-types';

import { ParametersTable } from 'components/shared';

const ActionLogs = ({ logs }) => (
  <ParametersTable
    key="actionLogs"
    params={logs}
    columnNames={['User', 'Action', 'Details', 'Date']}
  />
);

ActionLogs.propTypes = {
  logs: PropTypes.arrayOf(PropTypes.shape({})),
};

ActionLogs.defaultProps = {
  logs: [],
};

export default ActionLogs;
