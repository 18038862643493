import React, { useEffect, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import 'chartjs-plugin-zoom';

export default function LineChart ({ isZooming, ...props }) {
  const chartRef = useRef();

  useEffect(() => {
    if (isZooming) {
      return;
    }

    chartRef.current.chartInstance.resetZoom();
  }, [isZooming]);

  return (
    <Line
      height="100px"
      {...props}
      ref={chartRef}
    />
  );
};