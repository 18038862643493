import moment from "moment";

import { createRequestTypes } from "actions/requestTypes";

export const LOAD_WEATHER_FORECAST = createRequestTypes(
  "LOAD_WEATHER_FORECAST"
);
export const LOAD_CURRENT_WEATHER = createRequestTypes("LOAD_CURRENT_WEATHER");

const API_KEY = "0d03fce52b6e731c1fa4f072d7d3988c";

export const loadWeatherForecast =
  (coordinates) => async (dispatch, getState) => {
    if (!coordinates || (!coordinates.latitude && !coordinates.longitude)) {
      return;
    }

    const requestDate = new Date();
    const weatherState = getState().weather.forecast || {};
    const coordinatesKey = Object.values(coordinates).join(",");

    const weatherForecastState = weatherState[coordinatesKey];

    const lastRequestDate =
      weatherForecastState && weatherForecastState.requestDate;

    if (
      (!weatherForecastState || !weatherForecastState.data) &&
      lastRequestDate &&
      moment(requestDate).isSame(moment(lastRequestDate), "day")
    ) {
      return;
    }

    dispatch({
      type: LOAD_WEATHER_FORECAST.REQUEST,
      payload: { coordinatesKey, requestDate },
    });

    try {
      const requestParams = `lat=${coordinates.latitude}&lon=${coordinates.longitude}`;
      const result = await fetch(
        `https://api.openweathermap.org/data/2.5/forecast?${requestParams}&cnt=3&APPID=${API_KEY}`
      );
      const json = await result.json();

      dispatch({
        type: LOAD_WEATHER_FORECAST.SUCCESS,
        payload: { coordinatesKey, data: json.list },
      });
    } catch (error) {
      dispatch({
        type: LOAD_WEATHER_FORECAST.FAILURE,
        payload: { coordinatesKey, error },
      });
    }
  };

export const loadCurrentWeather =
  (coordinates) => async (dispatch, getState) => {
    if (!coordinates || (!coordinates.latitude && !coordinates.longitude)) {
      return;
    }

    const requestDate = new Date();

    const weatherState = getState().weather.current || {};
    const coordinatesKey = Object.values(coordinates).join(",");
    const weatherCurrentState = weatherState[coordinatesKey];

    const lastRequestDate =
      weatherCurrentState && weatherCurrentState.requestDate;

    if (
      lastRequestDate &&
      moment(requestDate).isSame(moment(lastRequestDate), "minutes")
    ) {
      return;
    }

    dispatch({
      type: LOAD_CURRENT_WEATHER.REQUEST,
      payload: { coordinatesKey, requestDate },
    });

    try {
      const requestParams = `lat=${coordinates.latitude}&lon=${coordinates.longitude}`;
      const result = await fetch(
        `https://api.openweathermap.org/data/2.5/weather?${requestParams}&APPID=${API_KEY}`
      );
      const json = await result.json();

      dispatch({
        type: LOAD_CURRENT_WEATHER.SUCCESS,
        payload: { coordinatesKey, data: json.main },
      });
    } catch (error) {
      dispatch({
        type: LOAD_CURRENT_WEATHER.FAILURE,
        payload: { coordinatesKey, error },
      });
    }
  };
