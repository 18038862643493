import React from 'react';
import { Placeholder } from 'semantic-ui-react';
import styled from "styled-components";
import { Card } from 'semantic-ui-react';

import { Card as CardComponent, CardContent as CardContentContainer } from 'components/shared';

const CardWrapper = styled.div`
  width: 100%;
`;

const WindmillLoadingCard = () => {
  return (
    <CardWrapper>
      <CardComponent fluid raised style={{ padding: '0 16px'}}>
        <CardContentContainer>
          <Card.Header>
            <Placeholder style={{ marginTop: 16 }}>
              <Placeholder.Header>
                <Placeholder.Line />
              </Placeholder.Header>
            </Placeholder>
          </Card.Header>
          <Card.Content>
            <div style={{ display: 'flex', justifyContent: 'space-between'}}>
              <Placeholder style={{ width: 100, height: 100, marginTop: 50 }}>
                <Placeholder.Image square />
              </Placeholder>
              <Placeholder style={{ width: 100, height: 100, marginTop: 50 }}>
                <Placeholder.Image square />
              </Placeholder>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-around',}}>
              <Placeholder style={{ width: 100, height: 16, marginTop: 32 }} />
              <Placeholder style={{ width: 100, height: 16, marginTop: 32 }} />
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between',}}>
              <Placeholder style={{ width: 100, height: 24, marginTop: 32 }} />
              <Placeholder style={{ width: 100, height: 24, marginTop: 32 }} />
            </div>
          </Card.Content>
        </CardContentContainer>
      </CardComponent>
    </CardWrapper>
  );
};

WindmillLoadingCard.propTypes = {};

WindmillLoadingCard.defaultProps = {};

export default WindmillLoadingCard;
