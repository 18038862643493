import React from "react";
import styled from "styled-components";

import { ParametersTable, ControlsVerticalMenu } from "components/shared";
import LiveControlButtons from "containers/live/LiveControlButtons";
import WeatherForecast from "containers/weather/WeatherForecast";
import { shouldDisplayParam, dec2bin } from "utils/params";

const Wrapper = styled.div``;

const getFormattedData = (params, liveParams, key) => {
  if (!params || !params[key]) return [];
  return params[key].filter(shouldDisplayParam).map((p) => {
    const key = p.dbname || p.name;
    let value;
    const liveParam = liveParams[key.toLowerCase()];
    if (liveParam !== undefined) {
      value = liveParam;
    }

    let val = "";
    if (value !== undefined) {
      val = `${value.toFixed(2)}`;
    }
    if (p.unit) {
      val = `${val} ${p.unit}`;
    }
    return { name: p.name, value: val };
  });
};

const getDIDOFormattedData = (params, liveParams, key) => {
  if (!params || !params[key] || !params[key] || !liveParams) return [];
  if (
    key === "DI" &&
    liveParams["di15_00"] === undefined &&
    liveParams["di23_16"] === undefined
  ) {
    return null;
  }
  if (key === "DO" && liveParams["digo"] === undefined) {
    return null;
  }

  const paramsSi15_00 = dec2bin(liveParams["di15_00"], 16).split("").reverse();
  const paramDi23_16 = dec2bin(liveParams["di23_16"], 8).split("").reverse();
  const paramDigO = dec2bin(liveParams["digo"]).split("").reverse();
  const allBinaryDiValues =
    key === "DI" ? paramsSi15_00.concat(paramDi23_16) : paramDigO;

  return params[key].map((p, index) => {
    let valKey = "value = 0";
    const liveParam = allBinaryDiValues[index];
    if (liveParam > 0) {
      valKey = "value = 1";
    }
    return {
      name: p.name,
      connector: p.connector,
      pin: p.pin,
      description: p.description,
      value: p[valKey],
    };
  });
};

const ditest = () => {
  alert("haide ma");
};

const LiveControlsMenu = ({ windTurbine, turbineParams, liveParams }) => {
  const render2ColumnsTable = (key, params) => {
    return (
      <ParametersTable key={`table:${key}`} boldIndexes={[0]} params={params} />
    );
  };

  const gridData = getFormattedData(turbineParams, liveParams, "grid");
  const rpmsData = [
    ...getFormattedData(turbineParams, liveParams, "revolution"),
    ...getFormattedData(turbineParams, liveParams, "revolutions"),
  ];
  const temperatureData = getFormattedData(
    turbineParams,
    liveParams,
    "temperature"
  );
  const vibrationsData = getFormattedData(
    turbineParams,
    liveParams,
    "vibration"
  );
  const diData = getDIDOFormattedData(turbineParams, liveParams, "DI");
  const doData = getDIDOFormattedData(turbineParams, liveParams, "DO");

  const CONTROLS = [
    {
      key: "CONTROLS",
      title: "CONTROLS",
      content: <LiveControlButtons windTurbine={windTurbine} />,
    },
    {
      key: "DIGITAL INPUTS",
      title: "DIGITAL INPUTS",
      content: (
        <Wrapper>
          {diData != null ? (
            <ParametersTable
              onClick={ditest}
              key="input"
              boldIndexes={[4]}
              params={diData}
              columnNames={["Name", "Connector", "Pin", "Description", "Value"]}
            />
          ) : (
            "No value provided  "
          )}
        </Wrapper>
      ),
    },
    {
      key: "DIGITAL OUTPUTS",
      title: "DIGITAL OUTPUTS",
      content: (
        <Wrapper>
          {doData != null ? (
            <ParametersTable
              key="output"
              boldIndexes={[4]}
              columnNames={["Name", "Connector", "Pin", "Description", "Value"]}
              params={doData}
            />
          ) : (
            "No value provided"
          )}
        </Wrapper>
      ),
    },
    {
      key: "GRID",
      title: "GRID",
      content: render2ColumnsTable("GRID", gridData),
    },
    {
      key: "RPMS",
      title: "RPMS",
      content: render2ColumnsTable("RPMS", rpmsData),
    },
    {
      key: "TEMPERATURE",
      title: "TEMPERATURE",
      content: render2ColumnsTable("TEMPERATURE", temperatureData),
    },
    {
      key: "VIBRATIONS",
      title: "VIBRATIONS",
      content: render2ColumnsTable("VIBRATIONS", vibrationsData),
    },
    {
      title: "WEATHER FORECAST",
      content: (
        <WeatherForecast
          coordinates={{
            latitude: windTurbine.latitude,
            longitude: windTurbine.longitude,
          }}
        />
      ),
    },
  ];

  return <ControlsVerticalMenu controls={CONTROLS} windTurbine={windTurbine} />;
};

LiveControlsMenu.propTypes = {};

LiveControlsMenu.defaultProps = {};

export default React.memo(LiveControlsMenu);
