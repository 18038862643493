import React, { useState } from "react";
import {
  ControlsVerticalMenu,
  ParametersTable,
  Button,
} from "components/shared";
import { Search, Icon } from "semantic-ui-react";
import { PARAM_UPDATE } from "../../constants";
import { toast } from "react-toastify";
import { writeParam } from "utils/params";
import AdvancedControlButtons from "containers/advanced/AdvancedControlButtons";
import ActionLogs from "containers/advanced/ActionLogs";
import SettingsControlButtons from "./SettingsControlButtons";
import styled from "styled-components";
import useAxios from "axios-hooks";

const Wrapper = styled.div``;

const ControlsMenuAdvanced = ({
  windTurbine,
  turbineParams,
  liveParams,
  dispatch,
}) => {
  const [searchQuery, setSearchQuery] = useState("");

  const [{ data }, getRefresh] = useAxios({
    url: `/advanced`,
    params: {
      windTurbineIdentifier: windTurbine.identifier,
      windTurbineType: windTurbine.type.id,
      fetchPolicy: "no-cache",
    },
  });

  const handleSearchChange = (e, { value }) => {
    setSearchQuery(value);
  };

  const onIconClick = () => {
    if (searchQuery && searchQuery.length > 0) {
      setSearchQuery("");
    }
  };

  const onEditParameter = async (param, newValue) => {
    const paramDef = turbineParams.find((p) => p.number === param.number);
    newValue = newValue * paramDef.scale;
    try {
      getRefresh();
      const response = await writeParam(
        windTurbine.identifier,
        param.dbname,
        newValue
      );
      if (response.s) {
        toast.success("TMC Parameter edited successfully");
        dispatch({
          type: PARAM_UPDATE,
          payload: {
            windTurbineId: windTurbine.id,
            paramId: paramDef.dbname,
            newValue,
          },
        });
      }
    } catch (error) {
      toast.error("Failed to edit parameter");
    }
  };

  const parametersData = turbineParams
    .map((p) => {
      let scale = p.scale || 1;
      let value =
        liveParams[p.dbname] !== undefined
          ? (p.value = liveParams[p.dbname])
          : liveParams.default;
      return {
        dbname: p.dbname,
        number: p.number,
        name: p.name,
        value,
        min: p.min / scale,
        max: p.max / scale,
        unit: p.unit,
        default: p.default / scale
      };
    })
    .filter(
      (d) => d.name.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
    );

  const CONTROLS = [
    {
      key: "ADVANCED CONTROLS",
      title: "ADVANCED CONTROLS",
      content: <AdvancedControlButtons windTurbine={windTurbine} />,
    },
    {
      key: "PARAMETERS",
      title: "PARAMETERS",
      content: (
        <Wrapper>
          <Button
            style={{ margin: "0rem 0rem 1rem 0rem" }}
            onClick={() => {
              getRefresh();
            }}
          >
            Refresh parameters
          </Button>

          <Search
            loading={false}
            onSearchChange={handleSearchChange}
            showNoResults={false}
            value={searchQuery}
            style={{ margin: "0rem 0rem 1rem 0rem" }}
            icon={
              <Icon
                link
                name={
                  searchQuery && searchQuery.length > 0 ? "cancel" : "search"
                }
                onClick={onIconClick}
              />
            }
          />

          {parametersData ? (
            <ParametersTable
              key="parameters"
              boldIndexes={[1, 2]}
              paginated
              editable
              numberOfItemsPerPage={1000}
              params={parametersData}
              columnNames={["Name", "Value", "Unit"]}
              columnKeys={["name", "value", "unit"]}
              cellStyle={{ padding: "4px 50px 4px 50px" }}
              onEditParameter={onEditParameter}
            />
          ) : (
            "Loading..."
          )}
        </Wrapper>
      ),
    },
    {
      key: "SETTINGS",
      title: "SETTINGS",
      content: (
        <Wrapper>
          <SettingsControlButtons />
        </Wrapper>
      ),
    },
    {
      key: "ACTION LOGS",
      title: "ACTION LOGS",
      content: (
        <Wrapper>
          <ActionLogs />
        </Wrapper>
      ),
    },
  ];

  return <ControlsVerticalMenu controls={CONTROLS} windTurbine={windTurbine} />;
};

ControlsMenuAdvanced.propTypes = {};

ControlsMenuAdvanced.defaultProps = {};

export default ControlsMenuAdvanced;
