import React, { useEffect } from "react";
import styled from "styled-components";
import moment from "moment";

import WeatherDayForecast from "./WeatherDayForecast";

const Wrapper = styled.div``;

const DayWrapper = styled.div`
  display: inline-block;
  padding: 1rem;
`;

const WeatherForecast = ({
  coordinates,
  loadWeatherForecast,
  data,
  isLoading,
  error,
}) => {
  useEffect(() => {
    if (!data || !data.length) {
      loadWeatherForecast(coordinates);
    }
  }, [coordinates, data, loadWeatherForecast]);

  if (!isLoading && !data) {
    return <> No data available </>;
  }

  return (
    <>
      {data &&
        data.map((d, index) => {
          let day = "Today";
          if (index === 1) {
            day = "Tomorrow";
          }
          if (index === 2) {
            day = moment(d.dt_txt).format("DD MMMM YYYY");
          }
          return (
            <DayWrapper key={day}>
              <WeatherDayForecast
                weather={d.weather[0]}
                day={day}
                windDirection={d.wind.deg}
                wind={d.wind.speed}
                dayTemperature={(d.main.temp_max - 273.15).toFixed(1)}
                nightTemperature={(d.main.temp_min - 273.15).toFixed(1)}
              />
            </DayWrapper>
          );
        })}
    </>
  );
};

export default WeatherForecast;
