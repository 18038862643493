import React, { useState } from 'react';
import { sessionService } from "redux-react-session";
import { toast } from 'react-toastify';

import { LoginForm } from 'components/auth/forms';
import { REMEMBER_LOGIN, LOGGED_IN } from '../../constants';

import useAxios from 'axios-hooks'

const onLoginError = (e) => {
  toast.error('Invalid credentials');
};

const onLoginSuccess = (data, rememberLogin) => {
  const { token, user } = data;
  localStorage.setItem(REMEMBER_LOGIN, JSON.stringify(rememberLogin));
  localStorage.setItem(LOGGED_IN, JSON.stringify(1));
  localStorage.setItem('token', token);
  sessionService.saveSession({ token });
  sessionService.saveUser(user);
};

const LoginFormContainer = () => {
  const [rememberLogin, setRememberLogin] = useState(false);

  const [{ loading, data, error  }, login] = useAxios({
    url: "/auth/login",
    method: "POST"
  }, {
    manual: true
  });

  if (error) {
    onLoginError(error)
  }

  if (data) {
    onLoginSuccess(data, rememberLogin)
  }

  const onSubmitLogin = (email, password, rememberLogin) => {
    setRememberLogin(rememberLogin);
    login({ data: { email, password }, onError: err => console.log(err) });
  };

  return (
    <LoginForm
      onSubmitLogin={onSubmitLogin}
      isLoading={loading}
    />
  )
};

export default LoginFormContainer;
