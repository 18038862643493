import React from "react";
import styled from "styled-components";
import { ScreenSizes } from "components/shared";

const StyledAuthentication = styled.div`
  max-width: 30rem;
  margin: auto;

  ${ScreenSizes.lessThan("mobile")`
margin: auto !important;
`}
`;

const AuthRoute = ({ children }) => {
  return <StyledAuthentication>{children}</StyledAuthentication>;
};

export default AuthRoute;
