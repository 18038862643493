import { TURBINE_LAST_UPDATE } from "../constants";

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case TURBINE_LAST_UPDATE: {
      const { windTurbineId } = action.payload;
      return {
        ...state,
        [windTurbineId]: {
          ...state[windTurbineId],
          lastUpdate: action.payload.data,
        },
      };
    }

    default:
      return state;
  }
};
