import React from "react";
import PropTypes from "prop-types";

import { Input } from "components/shared";

const userIcon = require("../../assets/images/user.svg");
const lockIcon = require("../../assets/images/lock.svg");

const iconStyle = {
  position: "static",
};

const Icon = ({ secureText }) => {
  return (
    <i className="icon" style={iconStyle}>
      <img src={secureText ? lockIcon : userIcon} alt="" />
    </i>
  );
};

const InputField = ({ secureText, ...otherProps }) => {
  return (
    <Input iconposition="left" {...otherProps}>
      <Icon secureText={secureText} />
      <input />
    </Input>
  );
};

InputField.propTypes = {
  secureText: PropTypes.bool,
};

InputField.defaultProps = {
  secureText: false,
};

export default InputField;
