import { connect } from 'react-redux';
import DashboardCardContent from 'components/dashboard/DashboardCardContent';
import { getLiveParams } from "selectors/liveParams";

const LIVE_PARAMS = 'all';

const mapStateToProps = (state, ownProps) => ({
  liveProps: getLiveParams(state, ownProps.windTurbine.id, LIVE_PARAMS),
  turbineState: state.turbineState[ownProps.windTurbine.id]
});

export default connect(mapStateToProps)(DashboardCardContent);
