import React, { useContext, useState, useEffect } from "react";
import styled, { ThemeContext } from "styled-components";
import { ProductionDetails } from "components/shared";
import { Grid } from "semantic-ui-react";
import PropTypes from "prop-types";
import moment from "moment";
import useAxios from "axios-hooks";
import "flag-icon-css/css/flag-icon.css";

const DetailedProductionRows = styled((props) => <Grid.Row {...props} />)`
  margin-top: ${(props) => (props.fulldetails ? "0.5rem" : undefined)};
  &:nth-child(1) {
    margin-top: 1rem;
  }
`;

const GridMobileRow = styled((props) => <Grid.Row {...props} />)`
  row-gap: ${(props) => props.rowgap};
`;

const CardFooter = ({
  fullDetails,
  productions,
  children,
  windTurbine,
  turbineParams,
}) => {
  const themeContext = useContext(ThemeContext);
  const [reportData, setReportData] = useState();
  const [todayProduction, setTodayProduction] = useState();
  const [thisMonthProduction, setThisMonthProduction] = useState();
  const [thisYearProduction, setThisYearProduction] = useState();
  const [totalProduction, setTotalProduction] = useState();
  const [avgWindToday, setAvgWindToday] = useState();
  const [avgWindThisMonth, setAvgWindThisMonth] = useState();
  const [avgPowerToday, setAvgPowerToday] = useState();
  const [avgPowerThisMonth, setAvgPowerThisMonth] = useState();

  const [{ data, loading }, getData] = useAxios(
    {
      url: `/productionData/summary/${windTurbine.id}`,
    },
    {
      useCache: true,
      manual: true,
    }
  );

  const [reportResponse, getReport] = useAxios(
    {
      url: `/productionData/report/${windTurbine.id}`,
    },
    {
      useCache: true,
      manual: true,
    }
  );

  useEffect(() => {
    if (fullDetails) {
      if (reportResponse.data) {
        setReportData(reportResponse.data);
      } else {
        if (!reportResponse.loading) {
          getReport();
        }
      }
    } else {
      if (data) {
        setReportData(data);
      } else {
        if (!loading) {
          getData();
        }
      }
    }
  }, [reportResponse, data]);

  function getParamByKey(turbineParams, key) {
    return turbineParams.find(
      (p) => p.dbname.toLowerCase() === key.toLowerCase()
    );
  }

  useEffect(() => {
    function getProductionValuesByKey(key) {
      if (!reportData || !reportData.length > 0) return 0;
      const info = reportData.find((d) => d.type === key);
      if (!info) return 0;
      return info.value;
    }

    if (reportData && turbineParams && turbineParams.length > 0) {
      const windScale = getParamByKey(turbineParams, "wind").scale || 1;
      const powerScale = getParamByKey(turbineParams, "power").scale || 1;
      setAvgPowerToday(getProductionValuesByKey("todayAvgPower") / powerScale);
      setAvgWindToday(getProductionValuesByKey("todayAvgWind") / windScale);
      setAvgPowerThisMonth(
        getProductionValuesByKey("thisMonthAvgPower") / powerScale
      );
      setAvgWindThisMonth(
        getProductionValuesByKey("thisMonthAvgWind") / windScale
      );

      const kwhParam = getParamByKey(turbineParams, "kwh_meter");
      const scale = kwhParam && kwhParam.scale ? kwhParam.scale : 1;
      const todayProd = getProductionValuesByKey("startToday");
      const thisMonthProd = getProductionValuesByKey("startThisMonth");
      const thisYearProd = getProductionValuesByKey("startThisYear");
      const latestLog = getProductionValuesByKey("latestLog");

      setTodayProduction(todayProd / scale);
      setThisMonthProduction(thisMonthProd / scale);
      setThisYearProduction(thisYearProd / scale);
      setTotalProduction(latestLog / scale);
    }
  }, [reportData, turbineParams]);

  function formatNumber(number) {
    return new Intl.NumberFormat("en-GB", { maximumFractionDigits: 2 }).format(
      number
    );
  }

  function renderDailyProduction() {
    const maxPower = windTurbine.maxPower || 60;
    const maxValue = maxPower * 24;
    return (
      <ProductionDetails
        key="daily"
        value={formatNumber(todayProduction)}
        maxValue={maxValue}
        title="Produced today"
        details={`${formatNumber(todayProduction)} kWH`}
        color={themeContext.primaryColor}
      />
    );
  }

  function renderMonthlyProduction() {
    const maxPower = windTurbine.maxPower || 60;
    const maxValue = maxPower * 24 * moment(Date.now()).daysInMonth();
    return (
      <ProductionDetails
        key="monthly"
        value={formatNumber(thisMonthProduction)}
        maxValue={maxValue}
        title="Monthly production"
        details={`${formatNumber(thisMonthProduction)} kWH`}
        color={themeContext.primaryColor}
      />
    );
  }

  function renderYearlyProduction() {
    const maxPower = windTurbine.maxPower || 60;
    const maxValue = maxPower * 24 * moment(Date.now()).daysInMonth() * 12;
    return (
      <ProductionDetails
        key="yearly"
        value={formatNumber(thisYearProduction)}
        maxValue={maxValue}
        title="Yearly production"
        details={`${formatNumber(thisYearProduction)} kWH`}
        color={themeContext.primaryColor}
      />
    );
  }

  function renderTotalProduction() {
    const maxPower = windTurbine.maxPower || 60;
    const maxValue = maxPower * 24 * moment(Date.now()).daysInMonth() * 12 * 10;
    return (
      <ProductionDetails
        key="total"
        value={totalProduction}
        maxValue={maxValue}
        title="Total production"
        details={`${formatNumber(totalProduction)} kWH`}
        color={themeContext.primaryColor}
      />
    );
  }

  function renderAvgPowerDaily() {
    return (
      <ProductionDetails
        key="avg-daily"
        value={moment(Date.now()).hours()}
        maxValue={24}
        title="Avg. power today"
        details={`${formatNumber(avgPowerToday)} kW`}
        withProgress={false}
      />
    );
  }

  function renderAvgPowerMonthly() {
    return (
      <ProductionDetails
        key="avg-monthly"
        value={moment(Date.now()).date()}
        maxValue={moment(Date.now()).daysInMonth()}
        title="Avg. power this month"
        details={`${formatNumber(avgPowerThisMonth)} kW`}
        withProgress={false}
      />
    );
  }

  function renderAvgWindDaily() {
    return (
      <ProductionDetails
        key="avg-wind-daily"
        value={moment(Date.now()).hours()}
        maxValue={24}
        title="Avg. wind today"
        details={`${formatNumber(avgWindToday)} m/s`}
        withProgress={false}
      />
    );
  }

  function renderAvgWindMonthly() {
    return (
      <ProductionDetails
        key="avg-wind-month"
        value={moment(Date.now()).date()}
        maxValue={moment(Date.now()).daysInMonth()}
        title="Avg. wind this month"
        details={`${formatNumber(avgWindThisMonth)} m/s`}
        withProgress={false}
      />
    );
  }

  const renderDashboardFooterProductionValues = () => {
    return (
      <Grid columns={2} stretched centered>
        <Grid.Column>{renderDailyProduction()}</Grid.Column>
        <Grid.Column>{renderMonthlyProduction()}</Grid.Column>
      </Grid>
    );
  };

  const renderDashboardDetailsInsideCard = () => {
    return (
      <div className="mobile hidden">
        <Grid style={{ padding: "1rem" }}>
          <DetailedProductionRows fulldetails={fullDetails ? 1 : 0}>
            <Grid.Column style={{ flex: 1 }}>
              {renderDailyProduction()}
            </Grid.Column>
            <Grid.Column style={{ flex: 1 }}>
              {renderMonthlyProduction()}
            </Grid.Column>
            <Grid.Column style={{ flex: 1 }}>
              {renderYearlyProduction()}
            </Grid.Column>
            <Grid.Column style={{ flex: 1 }}>
              {renderTotalProduction()}
            </Grid.Column>
          </DetailedProductionRows>
          <DetailedProductionRows fulldetails={fullDetails ? 1 : 0}>
            <Grid.Column style={{ flex: 1 }}>
              {renderAvgPowerDaily()}
            </Grid.Column>
            <Grid.Column style={{ flex: 1 }}>
              {renderAvgPowerMonthly()}
            </Grid.Column>
            <Grid.Column style={{ flex: 1 }}>
              {renderAvgWindDaily()}
            </Grid.Column>
            <Grid.Column style={{ flex: 1 }}>
              {renderAvgWindMonthly()}
            </Grid.Column>
          </DetailedProductionRows>
        </Grid>
      </div>
    );
  };

  const renderDashboardDetailsInsideCardMobile = () => {
    return (
      <div className="mobile only">
        <Grid columns={2} rows={4} style={{ padding: "1rem" }}>
          <GridMobileRow>
            <Grid.Column>{renderDailyProduction()}</Grid.Column>
            <Grid.Column>{renderMonthlyProduction()}</Grid.Column>
          </GridMobileRow>
          <GridMobileRow>
            <Grid.Column>{renderYearlyProduction()}</Grid.Column>
            <Grid.Column>{renderTotalProduction()}</Grid.Column>
          </GridMobileRow>
          <GridMobileRow rowgap="2rem">
            <Grid.Column>{renderAvgPowerDaily()}</Grid.Column>
            <Grid.Column>{renderAvgPowerMonthly()}</Grid.Column>
            <Grid.Column>{renderAvgWindDaily()}</Grid.Column>
            <Grid.Column>{renderAvgWindMonthly()}</Grid.Column>
          </GridMobileRow>
        </Grid>
      </div>
    );
  };

  const renderEnergyProductions = () => {
    return fullDetails ? (
      <div>
        {renderDashboardDetailsInsideCard()}
        {renderDashboardDetailsInsideCardMobile()}
      </div>
    ) : (
      renderDashboardFooterProductionValues()
    );
  };

  return (
    <>
      {renderEnergyProductions()}
      {children}
    </>
  );
};

CardFooter.propTypes = {
  fullDetails: PropTypes.bool,
  productions: PropTypes.arrayOf(PropTypes.shape({})),
  windTurbine: PropTypes.object.isRequired,
  turbineParams: PropTypes.array,
};

CardFooter.defaultProps = {
  fullDetails: false,
  productions: [],
  turbineParams: [],
};

export default React.memo(CardFooter);
