import { sessionService } from "redux-react-session";
import io from "socket.io-client";

import { SOCKET_URL } from "./constants";

let socket = null;

export async function getCurrentSocket() {
    if (!socket) {
        const session = await sessionService.loadSession();
        socket = io(SOCKET_URL, { query: { token: session.token } });
    } 
    socket.connect();
    return socket;
}