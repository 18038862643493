import React from 'react';
import { Table } from 'semantic-ui-react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import RPM from 'components/dashboard/RPM';
import Temperature from 'containers/weather/Temperature';
import PropTypes from "prop-types";

const StyledRow = styled(Table.Row)`
  &&& {
    border: 2px solid black;
    
    &:hover {
      cursor: pointer;
    }
  }
`;

const WindTurbinesListItem = ({ history, windTurbine, liveProps }) => {
  const onListItemClick = () => {
    history.push(`/${windTurbine.id}`);
  };

  return (
    <StyledRow selectable="true" onClick={onListItemClick}>
      <Table.Cell>{windTurbine.name}</Table.Cell>
      <Table.Cell>{liveProps.state}</Table.Cell>
      <Table.Cell>{liveProps.wind && `${liveProps.wind} m/s`}</Table.Cell>
      <Table.Cell>{liveProps.power && `${liveProps.power} kW`}</Table.Cell>
      <Table.Cell>
        <RPM hideLabel value={liveProps.rpm_rotor} />
      </Table.Cell>
    </StyledRow>
  )
};

WindTurbinesListItem.propTypes = {
  liveProps: PropTypes.shape({
    power: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    wind: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    rpm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    state: PropTypes.string,
  }),
  windTurbine: PropTypes.shape({ }).isRequired,
  onCardClick: PropTypes.func,
};

WindTurbinesListItem.defaultProps = {
  disabled: false,
  fullDetails: false,
  liveProps: {
    power: null,
    wind: null,
    rpm: null,
    state: null,
  },
  onListItemClick: () => {},
};

export default withRouter(WindTurbinesListItem);
