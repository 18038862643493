import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { Button, Icon } from "semantic-ui-react";
import { ContentContainer } from "components/shared";
import WindTurbinesGrid from "./WindTurbinesGrid";
import WindTurbinesMap from "./WindTurbinesMap";
import WindTurbinesList from "./WindTurbinesList";
import { TURBINE_VIEW_MODES } from "../../constants";

const ViewModesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

const VIEW_MODE_BUTTONS = Object.values(TURBINE_VIEW_MODES);

const Main = ({ viewMode, setViewMode, ...props }) => {
  const themeContext = useContext(ThemeContext);

  //TODO: extract view mode buttons in separate component and import it where needed

  const renderViewModeButton = (button) => {
    const isSelected = viewMode === button;
    const backgroundcolor = isSelected
      ? themeContext.accentColor
      : "transparent";
    const iconColor = isSelected ? "#fff" : themeContext.textDark;

    const style = {
      WiewModeButton: {
        marginLeft: 4,
        height: 36,
        borderRadius: 18,
        backgroundColor: `${backgroundcolor}`,
      },
    };

    return (
      <Button
        key={button}
        icon
        style={style.WiewModeButton}
        onClick={() => setViewMode(button)}
      >
        <Icon name={button} style={{ color: iconColor }} />
      </Button>
    );
  };

  const renderRightContent = () => (
    <ViewModesWrapper>
      {VIEW_MODE_BUTTONS.map(renderViewModeButton)}
    </ViewModesWrapper>
  );

  let content;

  switch (viewMode) {
    case TURBINE_VIEW_MODES.GRID:
      content = <WindTurbinesGrid {...props} />;
      break;
    case TURBINE_VIEW_MODES.MAP:
      content = <WindTurbinesMap {...props} />;
      break;
    case TURBINE_VIEW_MODES.LIST:
      content = <WindTurbinesList {...props} />;
      break;

    default:
      break;
  }

  return (
    <ContentContainer renderRightContent={renderRightContent}>
      {content}
    </ContentContainer>
  );
};

export default Main;
