import React, { useContext, useState } from "react";
import styled, { ThemeContext } from "styled-components";
import { Accordion, Icon } from "semantic-ui-react";
import useAxios from "axios-hooks";

import { Text } from "components/shared";

const Wrapper = styled.div`
  padding: 1rem;
  background-color: ${(props) => props.theme.controlsBackgroundColor};
`;

const TitleButton = styled(Text)`
  color: ${(props) => props.theme.accentColor};
  font-weight: 600;
`;

const ControlsVerticalMenu = ({ controls, windTurbine }) => {
  const [activeIndexes, setActiveIndexes] = useState([]);

  const [, getRealtime] = useAxios(
    {
      url: "/details",
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const themeContext = useContext(ThemeContext);

  const handleClick = (e, { index, title, active }) => {
    const isActive = activeIndexes.indexOf(index) > -1;
    let newIndexes = [...activeIndexes];

    const titles = ["GRID", "RPMS", "TEMPERATURE", "VIBRATIONS"];

    if (titles.includes(title)) {
      const action = active ? "unsubscribe" : "subscribe";

      getRealtime({
        params: {
          param: title,
          action: action,
          windTurbineId: windTurbine.identifier,
          windTurbineType: windTurbine.type.id,
        },
      });
    }

    if (isActive) {
      newIndexes = activeIndexes.filter((i) => i !== index);
    } else {
      newIndexes.push(index);
    }

    setActiveIndexes(newIndexes);
  };

  return (
    <Wrapper>
      <Accordion exclusive={false} fluid>
        {controls.map((c, i) => {
          const isActive = activeIndexes.indexOf(i) > -1;
          return (
            <React.Fragment key={c.title}>
              <Accordion.Title
                title={c.title}
                active={isActive}
                index={i}
                onClick={handleClick}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Icon
                  name="dropdown"
                  style={{ color: themeContext.accentColor }}
                />
                <TitleButton size={13}>{c.title}</TitleButton>
              </Accordion.Title>
              <Accordion.Content active={isActive}>
                {c.content}
              </Accordion.Content>
            </React.Fragment>
          );
        })}
      </Accordion>
    </Wrapper>
  );
};

ControlsVerticalMenu.propTypes = {};

ControlsVerticalMenu.defaultProps = {};

export default ControlsVerticalMenu;
