import { connect } from 'react-redux';

import LiveControlsMenu from 'components/live/LiveControlsMenu';
import { getLiveParams } from "selectors/liveParams";

const mapStateToProps = (state, ownProps) => ({
  turbineParams: state.turbineParams[ownProps.windTurbine.id],
  liveParams: getLiveParams(state, ownProps.windTurbine.id, 'all'),
});

export default connect(mapStateToProps)(LiveControlsMenu);
