import React, { useState } from "react";
import AuthForm from "./AuthForm";
import InputField from "../InputField";
import PropTypes from "prop-types";
import { Button } from "components/shared/index";
import { Form } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { ThemeToggle } from "components";

const ForgotPasswordForm = ({ onSubmitForgotPassword, isLoading, history }) => {
  const [email, setEmail] = useState("");

  const onEmailChange = (ev, { value }) => {
    setEmail(value);
  };

  const onSubmit = async () => {
    onSubmitForgotPassword(email);
  };

  const returnToHomeScreen = () => {
    history.push("/");
  };

  return (
    <AuthForm>
      <Form style={{ width: "100%", padding: "2rem" }} onSubmit={onSubmit}>
        <Form.Field style={{ marginBottom: "2rem" }}>
          <InputField
            value={email}
            type="email"
            secureText
            onChange={onEmailChange}
            placeholder="Enter email"
            style={{ display: "flex", alignItems: "center" }}
          />
        </Form.Field>
        <Form.Field style={{ marginBottom: "2rem" }}>
          <Button
            type="submit"
            onSubmit={onSubmit}
            loading={isLoading}
            disabled={isLoading}
            style={{ width: "100%" }}
          >
            Reset Password
          </Button>
        </Form.Field>
        <Form.Field>
          <ThemeToggle></ThemeToggle>
        </Form.Field>
        <Form.Field style={{ marginBottom: "2rem" }}>
          <Button
            secondary
            onClick={(e) => {
              e.preventDefault();
              returnToHomeScreen();
            }}
          >
            Go Back
          </Button>
        </Form.Field>
      </Form>
    </AuthForm>
  );
};

ForgotPasswordForm.propTypes = {
  onSubmitForgotPassword: PropTypes.func,
};

ForgotPasswordForm.defaultProps = {
  onSubmitForgotPassword: () => {},
};

export default withRouter(ForgotPasswordForm);
