import { SET_THEME } from "../constants";
import { THEME } from "../constants";
import { SET_IS_IDLE } from "../constants";

const INITIAL_STATE = {
  theme: localStorage.getItem(THEME) || "light",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_THEME:
      return {
        ...state,
        theme: action.payload.theme,
      };

    case SET_IS_IDLE:
      return {
        ...state,
        isIdle: action.payload.isIdle,
      };

    default:
      return state;
  }
};
