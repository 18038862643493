import React from "react";
import { toast } from "react-toastify";
import useAxios from "axios-hooks";
import { ForgotPasswordForm } from "components/auth/forms";

const ForgotPasswordContainer = ({ history }) => {
  const [{ loading, data, error }, forgotPassword] = useAxios(
    {
      url: "/auth/forgotPassword",
      method: "POST",
    },
    {
      manual: true,
    }
  );

  if (data) {
    toast.success(data);
    history.push("/");
  }

  if (error) {
    toast.error(error.response.data.error);
  }

  const onSubmitForgotPassword = (email) => {
    forgotPassword({ data: { email } });
  };

  return (
    <ForgotPasswordForm
      onSubmitForgotPassword={onSubmitForgotPassword}
      isLoading={loading}
    />
  );
};

export default ForgotPasswordContainer;
