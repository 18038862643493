import React, { useContext } from 'react';
import { Menu, Logo } from 'components/shared';
import { connect } from 'react-redux';
import AccountHeader from 'containers/header/AccountHeader';
import styled, { ThemeContext } from 'styled-components';

const Wrapper = styled.div`
display: flex;
border-bottom-color: ${props => props.theme.shadowColor};
background-color: ${props => props.theme.headerBackgroundColor};
align-items: center;
justify-items: center;
border-radius: 1rem 1rem 0 0;
`;

const WrapperColumns = styled.div`
flex: 1;
display: flex;
align-items: center;
justify-content: ${(props) => props.contentAlignment};
padding-top: 1rem;
padding-bottom: 1rem;
gap: 1rem;
`;

const LINKS = [{
  label: 'DASHBOARD',
  to: '/'
}, {
  label: 'LICENSES',
  to: '/licenses'
}];

const LargeScreenHeader = ({ theme, visibility }) => {
  const themeContext = useContext(ThemeContext);

  return (
    <Wrapper className={visibility}>
      <WrapperColumns contentAlignment="flex-start">
        <Logo theme={theme} />
      </WrapperColumns>
      <WrapperColumns contentAlignment="space-around">
        <Menu links={LINKS} defaultActiveItem={LINKS[0].label} backgroundcolor={themeContext.headerBackgroundColor} />
        <AccountHeader />
      </WrapperColumns>
    </Wrapper>
  );
};

LargeScreenHeader.propTypes = {
};

LargeScreenHeader.defaultProps = {
};

const mapStateToProps = state => ({
  theme: state.settings.theme,
});

export default connect(mapStateToProps)(LargeScreenHeader);
