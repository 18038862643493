import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import WindTurbineListItem from 'containers/dashboard/WindTurbineListItem';
import { EmptyView } from 'components/shared';
import { Icon, Search, Table, Loader } from 'semantic-ui-react';
import { ThemeContext } from 'styled-components';

const StyledTable = styled(Table)`

  &&& {
    background-color: #fff;
    box-shadow: 1px 1px 4px 2px rgba(0,0,0,0.1);
    width: 100% !important;
  }
`;

const WindTurbinesList = ({ data, isLoading }) => {
  const themeContext = useContext(ThemeContext);
  const [searchQuery, setSearchQuery] = useState('');
  const [isMobile, updateIsMobile] = useState(false);

  const renderListItem = (w) => (
    <WindTurbineListItem key={w.id} windTurbine={w} />
  );

  useEffect(() => {
    if (window.innerWidth < 768) {
      updateIsMobile(true);
    }
  })

  useEffect(() => {
    const handleResize = () => {
      if (isMobile && window.innerWidth > 768) {
        updateIsMobile(false);
      }

      if (!isMobile && window.innerWidth < 768 || window.innerWidth < 768) {
        updateIsMobile(true);
      }
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [isMobile, updateIsMobile, window])

  if (!data || !data.length) {
    return <EmptyView style={{ marginTop: 100 }} />;
  }

  if (isLoading) {
    return <Loader active={true} inline="centered" />
  }

  const handleSearchChange = (e, { value }) => {
    setSearchQuery(value);
  };

  const onCancelSearch = () => {
    if (searchQuery && searchQuery.length > 0) {
      setSearchQuery('');
    }
  };

  let dataToShow = data;
  if (searchQuery && searchQuery.length > 0) {
    dataToShow = data.filter(d => d.name.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1);
  }

  return (
    <>
      <Search
        loading={false}
        onSearchChange={handleSearchChange}
        showNoResults={false}
        value={searchQuery}
        icon={<Icon link name={searchQuery && searchQuery.length > 0 ? 'cancel' : 'search'} onClick={onCancelSearch} />}
        style={{ padding: "1rem" }}
      />

      <StyledTable singleLine basic="very" size="large" style={{ padding: isMobile ? "0rem" : "1rem", backgroundColor: themeContext.backgroundcolor }} size="small">
        <Table.Header className="mobile hidden">
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>State</Table.HeaderCell>
            <Table.HeaderCell>Wind</Table.HeaderCell>
            <Table.HeaderCell>kW</Table.HeaderCell>
            <Table.HeaderCell>RPM</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {dataToShow.map(renderListItem)}
        </Table.Body>
      </StyledTable>
    </>
  )
};

export default WindTurbinesList;
