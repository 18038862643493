import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from 'components/shared';
import { Dropdown, Icon } from 'semantic-ui-react';

const Wrapper = styled.div`
`;

const ContentWrapper = styled.div`
margin-right: 1rem;
`;

const UserName = styled.span`
  color: ${props => props.theme.textMediumDark};
  font-size: 13px;
  font-weight: 700;
`;

const UserEmail = styled.span`
  font-size: 12px;
  color: ${props => props.theme.textMedium};
`;

const styles = {
  button: {
    backgroundcolor: 'transparent',
    boxShadow: '0 0 0 transparent',
    color: '#fff'
  },
  user: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    alignItems: "center",
    justifyItems: "start"
  }
};

const AccountHeader = ({ logout, user }) => {
  const options = [
    { key: 'sign-out', text: 'Sign Out', icon: 'sign out', onClick: logout },
  ];

  const trigger = (
    <Wrapper>
      <div className="mobile hidden" style={styles.user}>
        <ContentWrapper>
          <UserName>{user ? user.name : ''}</UserName>
          <UserEmail>{user ? user.email : ''}</UserEmail>
        </ContentWrapper>
        <Icon name="user circle" style={{ color: "#1AA68C" }} size="big" />
      </div>
      <div className="mobile only">
        <Button style={styles.button} icon>
          <Icon name="user circle" />
        </Button>
      </div>
    </ Wrapper>
  );

  return (
    <Dropdown
      trigger={trigger}
      options={options}
      pointing='top right'
      icon={null}
    />
  );
};

AccountHeader.propTypes = {
  logout: PropTypes.func,
};

AccountHeader.defaultProps = {
  logout: () => { },
};

export default AccountHeader;
