import { connect } from 'react-redux';

import ControlsMenu from 'components/advanced/ControlsMenu';
import { getTurbineParams } from "selectors/turbineParams";
import { getLiveParams } from 'selectors/liveParams';

const mapStateToProps = (state, ownProps) => ({
  turbineParams: getTurbineParams(state, ownProps.windTurbine.id),
  liveParams: getLiveParams(state, ownProps.windTurbine.id, "all")
});

export default connect(mapStateToProps)(ControlsMenu);
