import React, { useState, useEffect, useContext } from "react";
import { Menu, MenuItem } from "semantic-ui-react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import styled, { ThemeContext } from "styled-components";

const SelectedButtonIndicator = styled.div`
background - color: ${(props) => props.theme.accentColor};
height: 0.25rem;
border - radius: 2px;
position: absolute;
left: 0;
right: 0;
bottom: 0.2rem;
`;

const MenuStyled = styled((props) => <Menu {...props} />)`
  margin: auto !important;
  gap: 1rem;
`;

const NavigationLinks = ({
  activeLink,
  backgroundcolor,
  controlled = false,
  defaultActiveItem,
  disabledButtonIndexes,
  links,
  onItemClick,
  withoutNavigation,
  elementWidth,
  elementHeight,
  autoFontAdjustment,
  layout,
}) => {
  const themeContext = useContext(ThemeContext);
  const [activeItem, setActiveItem] = useState(defaultActiveItem);
  const [menuSize, updateMenuSize] = useState("small");
  const [screenSize, updateScreenSize] = useState("mobile");

  useEffect(() => {
    if (controlled) {
      setActiveItem(activeLink);
    }
  }, [activeLink, controlled]);

  useEffect(() => {
    const handleResize = () => {
      if (menuSize !== "huge" && window.innerWidth < 768) {
        updateMenuSize("huge");
      }

      if (menuSize !== "small" && window.innerWidth > 768) {
        updateMenuSize("small");
      }

      if (window.innerWidth < 768 && screenSize !== "mobile") {
        updateScreenSize("mobile");
      }

      if (window.innerWidth > 768 && screenSize !== "not mobile") {
        updateScreenSize("not mobile");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [menuSize, screenSize, updateScreenSize, updateMenuSize, window]);

  const handleItemClick = (e, item) => {
    onItemClick(item.name);
    if (!controlled) {
      setActiveItem(item.name);
    }
  };

  const setMenuItemColor = (themeContext, isDisabled, isActive) => {
    return isDisabled
      ? themeContext.textMedium
      : isActive
      ? themeContext.textDark
      : themeContext.accentColor;
  };

  const createMenuItem = (
    backgroundcolor,
    themeContext,
    isDisabled,
    isActive,
    key,
    link
  ) => {
    return (
      <MenuItem
        key={key}
        as={withoutNavigation ? "div" : Link}
        to={withoutNavigation ? undefined : link.to}
        name={link.label}
        backgroundcolor={backgroundcolor}
        active={isActive}
        isdisabled={isDisabled.toString()}
        themecontext={themeContext}
        style={{
          backgroundcolor,
          fontWeight: "bold",
          color: setMenuItemColor(themeContext, isDisabled, isActive),
          padding: menuSize === "huge" ? "0rem" : "",
          width: elementWidth + "%",
          height: elementHeight + "vh",
          fontSize:
            autoFontAdjustment && screenSize === "mobile" ? "small" : "",
        }}
        onClick={isDisabled ? undefined : handleItemClick}
      >
        {link.label}
        {isActive && <SelectedButtonIndicator />}
      </MenuItem>
    );
  };

  return (
    <MenuStyled
      size={menuSize}
      secondary
      widths={links.length}
      stackable={true}
      links={links}
      style={{ display: screenSize === "mobile" ? `${layout}` : "" }}
    >
      {links.map((link, index) => {
        const isDisabled = disabledButtonIndexes.indexOf(index) > -1;
        const isActive = link.label === activeItem;

        return createMenuItem(
          backgroundcolor,
          themeContext,
          isDisabled,
          isActive,
          index,
          link
        );
      })}
    </MenuStyled>
  );
};

NavigationLinks.propTypes = {
  withoutNavigation: PropTypes.bool,
  onItemClick: PropTypes.func,
  backgroundcolor: PropTypes.string,
  disabledButtonIndexes: PropTypes.arrayOf(PropTypes.number),
  elementWidth: PropTypes.number,
  elementHeight: PropTypes.number,
  autoFontAdjustment: PropTypes.bool,
  layout: PropTypes.string,
};

NavigationLinks.defaultProps = {
  withoutNavigation: false,
  onItemClick: () => {},
  backgroundcolor: "#fff",
  disabledButtonIndexes: [],
  layout: "flex",
};

export default React.memo(NavigationLinks);
