import React, { useState, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import createActivityDetector from "activity-detector";
import { setIsIdle } from "actions/settings";
import { Confirm } from "semantic-ui-react";
import "react-toastify/dist/ReactToastify.css";
import "./toast.css";
import "./utils/auth";
import Router from "./routes/Router";
import theme from "theme";
import {
  subscribeToLiveParams,
  unsubscribeFromLiveParams,
} from "actions/liveParams";

toast.configure({
  autoClose: 4000,
});

function useIdle(options, setIsIdleAction, isIdle) {
  useEffect(() => {
    const activityDetector = createActivityDetector({
      ...options,
      autoInit: false,
    });
    activityDetector.on("idle", () => {
      const userLoggedIn = localStorage.getItem("LOGGED_IN");
      if (userLoggedIn === "1") {
        console.log("-----IDLE-----");
        setIsIdleAction(true);
      }
    });
    activityDetector.on("active", () => {
      console.log("-----ACTIVE-----");
      setIsIdleAction(false);
    });
    console.log("-----DETECTOR init-----");
    activityDetector.init();
    return () => activityDetector.stop();
  }, []); // eslint-disable-line
}

const App = ({
  themeName,
  setIsIdleAction,
  windTurbines,
  isIdle,
  subscribeToLiveParams,
  unsubscribeFromLiveParams,
}) => {
  const [showIdleModal, setShowIdleModal] = useState();
  useIdle(
    {
      timeToIdle: 300000,
      inactivityEvents: [],
    },
    setIsIdleAction
  );
  useEffect(() => {
    if (isIdle) {
      const turbinesArray = [];
      Object.keys(windTurbines).forEach((key) => {
        turbinesArray.push(windTurbines[key]);
      });
      unsubscribeFromLiveParams(turbinesArray);
      setShowIdleModal(true);
    }
  }, [isIdle, unsubscribeFromLiveParams, windTurbines]);

  const onConfirm = () => {
    const turbinesArray = [];
    Object.keys(windTurbines).forEach((key) => {
      turbinesArray.push(windTurbines[key]);
    });
    subscribeToLiveParams(turbinesArray, "dashboard");
    setShowIdleModal(false);
  };

  const onCancel = () => {
    setShowIdleModal(false);
  };

  return (
    <ThemeProvider theme={theme[themeName]}>
      <Router />
      <Confirm
        header="Restart monitoring?"
        content=""
        open={showIdleModal}
        onCancel={onCancel}
        onConfirm={onConfirm}
      />
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => ({
  themeName: state.settings.theme,
  isIdle: state.settings.isIdle,
  windTurbines: state.windTurbines,
});

const mapDispatchToProps = (dispatch, owProps) => ({
  setIsIdleAction: (isIdle) => dispatch(setIsIdle(isIdle)),
  subscribeToLiveParams: (windTurbines) =>
    dispatch(subscribeToLiveParams(windTurbines)),
  unsubscribeFromLiveParams: (windTurbines) =>
    dispatch(unsubscribeFromLiveParams(windTurbines)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
