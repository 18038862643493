import React from 'react';
import styled, { keyframes } from 'styled-components';
import { GiBoatPropeller } from 'react-icons/gi';
import { ScreenSizes } from 'components/shared';
import { Text } from 'components/shared';

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  ${ScreenSizes.lessThan('tablet')`
  padding: 0 0.5rem 0 0;
  `}
`;

const Value = styled(props => <Text {...props} />)`
  color: ${props => props.theme.textDark};
  font-weight: 400;
`;

const RPMText = styled(Value)`
  color: ${props => props.theme.textMedium};
  font-weight: 300;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const StyledIcon = styled(GiBoatPropeller)`
  animation: ${rotate} ${props => props.duration}s linear infinite;
`;

const RPM = ({ value, hideLabel = false }) => {

  if (Number.isInteger(value)) {
    value = value.toFixed(1);
  }

  let rotationDuration = 0;

  if (value > 0) {
    rotationDuration = 60 / value;
  }

  return (
    <Wrapper>
      <StyledIcon color="grey" size="1.5em" duration={rotationDuration} />
      <Value size={24}>{value || 0}</Value>
      {!hideLabel && <RPMText size={20}>RPM</RPMText>}
    </Wrapper>
  );
};

RPM.propTypes = {};

RPM.defaultProps = {};

export default RPM;
