import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { ScreenSizes } from "components/shared";
import { Logo, Text } from "components/shared";
import { connect } from "react-redux";

const AuthWrapper = styled.div`
  display: grid;
  background-color: ${props => props.theme.backgroundcolor};
  justify-items: center;
  grid-template-columns: 100%;
  height: 90vh;
  padding: 1rem;
  border-radius: 0.5rem;
  margin-top: 3rem;

  ${ScreenSizes.lessThan('mobile')`
  height: 100vh !important; 
  border-radius: 0rem;
  margin-top: 0rem;
  `}
`;

const LogoInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SupportText = styled((props) => <Text {...props} />)`
  word-break: break-word;
  font-weight: 600;
  color: ${(props) => props.theme.textMediumDark};
`;

const SupportLink = styled.a`
  word-break: break-word;
  font-weight: 600;
  &:hover {
    cursor: pointer;
  }
  color: ${(props) => props.theme.accentColor};
  margin-left: 5px;
`;

const AuthForm = ({ children, theme }) => {
  const themeContext = useContext(ThemeContext)
  return (
    <AuthWrapper theme={themeContext}>
      <LogoInfoWrapper>
        <Logo size="medium" theme={theme} />
      </LogoInfoWrapper>
      {children}
      <span style={{ alignSelf: "center", textAlign: "center" }}>
        <SupportText size={14}>If you need any help, contact us at</SupportText>
        <SupportLink href="mailto:info@orbitalitalia.it">
          info@orbitalitalia.it
        </SupportLink>
      </span>
    </AuthWrapper>
  );
};

AuthForm.propTypes = {};

AuthForm.defaultProps = {};

const mapStateToProps = state => ({
  theme: state.settings.theme,
});

export default connect(mapStateToProps)(AuthForm);