import { connect } from 'react-redux';

import LiveDataGraph from 'components/live/LiveDataGraph';
import { getLiveGraphParams } from "selectors/liveParams";
import { getAllMonitoringParameters } from 'selectors/turbineParams';

const mapStateToProps = (state, ownProps) => ({
  liveProps: getLiveGraphParams(state, ownProps.windTurbine.id),
  monitoringParams: getAllMonitoringParameters(state, ownProps.windTurbine.id),
});

export default connect(mapStateToProps)(LiveDataGraph);
