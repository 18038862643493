
function searchDbNamesInArrray(array, keys) {
  const lowerKeys = keys.map(k => k.toLowerCase());
  return array.filter(p => p.dbname && lowerKeys.indexOf(p.dbname.toLowerCase()) >= 0);
}
  
export const getTurbineParamsDefinitionByKey = (state, windTurbineId, keys) => {
  const turbineParamsState = state.turbineParams[windTurbineId];
  if (!turbineParamsState) {
    return [];
  }
  
  let paramsArray = [];
  Object.keys(turbineParamsState).forEach(p => {
    paramsArray = paramsArray.concat(searchDbNamesInArrray(turbineParamsState[p], keys));
  });
    
  return paramsArray;
};