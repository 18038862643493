import { connect } from 'react-redux';

import WindTurbineListItem from 'components/dashboard/WindTurbineListItem';
import { getLiveParams } from "selectors/liveParams";

const LIVE_PARAMS = 'all'; //['rpm_rotor', 'power', 'wind', 'state', 'kwh_meter'];

const mapStateToProps = (state, ownProps) => ({
  liveProps: getLiveParams(state, ownProps.windTurbine.id, LIVE_PARAMS),
});

export default connect(mapStateToProps)(WindTurbineListItem);
