import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Button = styled.button`
  background-color: ${(props) => props.theme.primaryButtonColor}
  color: ${(props) => props.theme.primaryButtonText};
  padding: ${(props) => (props.removePadding ? "0rem" : "1rem")};
  border: 1px thin;
  border-radius: 6px;
  text-align: center;
  outline: none;
  width: 10rem;
  height: 5rem;

  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.shadowColor}
  }
`;

const ControlButton = ({
  isHighlighted,
  children,
  removePadding,
  ...otherProps
}) => {
  const onClick = () => {};

  return (
    <Button
      isHighlighted={isHighlighted}
      removePadding={removePadding}
      onClick={onClick}
      {...otherProps}
    >
      {children}
    </Button>
  );
};

ControlButton.propTypes = {
  isHighlighted: PropTypes.bool,
};

ControlButton.defaultProps = {
  isHighlighted: false,
};

export default ControlButton;
