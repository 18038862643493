import {
  SET_VIEW_MODE,
  SET_PAGE_NUMBER,
  SET_TOTAL_PAGE_NUMBER,
  TURBINE_VIEW_MODES
} from "../constants";

const initialState = {
  viewMode: TURBINE_VIEW_MODES.GRID,
  pageNumber: 1,
  totalPageNumber: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_VIEW_MODE:
      return {
        ...state,
        viewMode: action.payload.viewMode,
      };
    case SET_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.payload.pageNumber,
      };
    case SET_TOTAL_PAGE_NUMBER:
      return {
        ...state,
        totalPageNumber: action.payload.totalPageNumber,
      };

    default:
      return state;
  }
};
