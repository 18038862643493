export const getWeatherForecast = (state, coordinates) => {
  if (!coordinates) {
    return null;
  }
  const weatherState = state.weather.forecast || {};
  const coordinatesKey = Object.values(coordinates).join(',');
  const weatherForecast = weatherState[coordinatesKey];

  return weatherForecast ? weatherForecast.data : [];
};

export const isLoadingWeatherForecast = (state, coordinates) => {
  if (!coordinates) {
    return null;
  }
  const weatherState = state.weather.forecast || {};
  const coordinatesKey = Object.values(coordinates).join(',');
  const weatherForecast = weatherState[coordinatesKey];

  return weatherForecast ? weatherForecast.isLoading : false;
};

export const loadWeatherForecastError = (state, coordinates) => {
  if (!coordinates) {
    return null;
  }
  const weatherState = state.weather.forecast || {};
  const coordinatesKey = Object.values(coordinates).join(',');
  const weatherForecast = weatherState[coordinatesKey];

  return weatherForecast ? weatherForecast.error : null;
};

export const getCurrentWeather = (state, coordinates) => {
  if (!coordinates) {
    return null;
  }
  const weatherState = state.weather.current || {};
  const coordinatesKey = Object.values(coordinates).join(',');
  const currentWeather = weatherState[coordinatesKey];
  return currentWeather && currentWeather.data && (currentWeather.data.temp - 273.15).toFixed(1);
};

export const isLoadingCurrentWeather = (state, coordinates) => {
  if (!coordinates) {
    return false;
  }
  const weatherState = state.weather.current || {};
  const coordinatesKey = Object.values(coordinates).join(',');
  const currentWeather = weatherState[coordinatesKey];

  return currentWeather ? currentWeather.isLoading : false;
};

export const loadCurrentWeatherError = (state, coordinates) => {
  if (!coordinates) {
    return null;
  }
  const weatherState = state.weather.current || {};
  const coordinatesKey = Object.values(coordinates).join(',');
  const currentWeather = weatherState[coordinatesKey];

  return currentWeather ? currentWeather.error : null;
};
