import React, { useState } from "react";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { Confirm } from "semantic-ui-react";
import { writeCommand } from "utils/params";
import useAxios from "axios-hooks";

const ExecuteActionButton = ({
  children,
  action,
  name,
  windTurbineIdentifier,
  match,
  onAfterActionClicked,
}) => {
  const [confirmationVisible, setConfirmationVisible] = useState(false);

  const onExecuteActionError = (e) => {
    toast.error(`Failed saving action ${name}`, { hideProgressBar: true });
  };

  const onExecuteActionSuccess = () => {
    toast.success(`Action ${name} saved`, { hideProgressBar: true });
    setConfirmationVisible(false);
  };

  const onExecuteTMCActionError = (e) => {
    toast.error(`TMC - Failed executing ${name}`, { hideProgressBar: true });
  };

  const onExecuteTMCActionSuccess = () => {
    toast.success(`TMC - ${name} executed successfully`, {
      hideProgressBar: true,
    });
  };

  const [{ loading }, executeAction] = useAxios(
    {
      url: `/windTurbines/${match.params.id}/executeAction`,
      method: "PUT",
    },
    {
      manual: true,
    }
  );

  const onExecuteAction = () => {
    executeAction({
      data: {
        action,
      },
    });
  };

  const onCancel = (ev) => {
    ev.preventDefault();
    setConfirmationVisible(false);
  };
  const handleClick = async (ev) => {
    let response = await writeCommand(windTurbineIdentifier, action);
    if (response.s === true) {
      onExecuteTMCActionSuccess(response);
      onExecuteAction(response);
    } else {
      onExecuteTMCActionError(response);
    }
    if (onAfterActionClicked) {
      onAfterActionClicked();
    }
  };

  return (
    <>
      <div onClick={handleClick}>
        {React.cloneElement(children, { isHighlighted: loading })}
      </div>
      <Confirm
        header="Are you sure?"
        content={`The following action will be executed: ${name}`}
        open={confirmationVisible}
        onCancel={onCancel}
        onConfirm={onExecuteAction}
      />
    </>
  );
};

export default withRouter(ExecuteActionButton);
