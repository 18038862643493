import { connect } from 'react-redux';

import WeatherForecast from 'components/weather/WeatherForecast';
import { loadWeatherForecast } from 'actions/weather';
import {
  getWeatherForecast, isLoadingWeatherForecast, loadWeatherForecastError,
} from 'selectors/weather';

const mapStateToProps = (state, ownProps) => ({
  data: getWeatherForecast(state, ownProps.coordinates),
  isLoading: isLoadingWeatherForecast(state, ownProps.coordinates),
  error: loadWeatherForecastError(state, ownProps.coordinates),
});

export default connect(mapStateToProps, {
  loadWeatherForecast
})(WeatherForecast);
