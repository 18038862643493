import React from 'react';
import { withRouter } from 'react-router-dom';

import WindTurbineCard from 'components/dashboard/WindTurbineCard';

const WindTurbineCardContainer = ({ history, windTurbine }) => {
  const onCardClick = () => {
    history.push(`/${windTurbine.id}`);
  };

  return (
    <WindTurbineCard
      onCardClick={onCardClick}
      windTurbine={windTurbine}
    />
  )
};

export default React.memo(withRouter(WindTurbineCardContainer));
