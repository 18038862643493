import React, { useContext } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { EmptyView } from 'components/shared';
import { Pagination, Segment } from 'semantic-ui-react';
import { ThemeContext } from 'styled-components';
import WindTurbineCard from 'containers/dashboard/WindTurbineCard';
import WindTurbineLoadingCard from 'components/dashboard/WindTurbineLoadingCard';

const WindTurbineWrapper = styled.div`
  
  margin-bottom: 16px;
  display: inline-block;
  padding: 0 8px;
  width: 33%;
  
  ${media.between('medium', 'large')`
    padding: 0 16px;
    width: 50%;
  `}
  ${media.lessThan('medium')`
    width: 100%;
  `}
`;

const CustomPagination = styled((props) => <Pagination {...props} />)`
&&& {
flex-wrap: wrap;
background-color: ${(props) => props.theme.backgroundcolor};

.item {
  color: gray;
}
}
`

const PLACEHOLDERS = [
  { id: 'placeholder1' }, { id: 'placeholder2' }, { id: 'placeholder3' },
  { id: 'placeholder4' }, { id: 'placeholder5' }, { id: 'placeholder6' }
];

const WindTurbinesGrid = ({ data, isLoading, pageNumber, setPageNumber, totalPageNumber }) => {
  const themeContext = useContext(ThemeContext);

  const renderWindTurbine = (windTurbine) => (
    <WindTurbineWrapper key={windTurbine.id}>
      <WindTurbineCard windTurbine={windTurbine} />
    </WindTurbineWrapper>
  );

  const renderWindTurbinePlaceholder = ({ id }) => (
    <WindTurbineWrapper key={id}>
      <WindTurbineLoadingCard />
    </WindTurbineWrapper>
  );

  let content;

  if (isLoading) {
    content = PLACEHOLDERS.map(renderWindTurbinePlaceholder);
  } else {
    if (data && data.length) {
      content = <div>
        {data.map(renderWindTurbine)}
        <Segment basic textAlign={"center"}>
          <CustomPagination
            theme={themeContext}
            activePage={pageNumber}
            totalPages={totalPageNumber}
            onPageChange={(e, { activePage }) => {
              setPageNumber(activePage)
            }}
          />
        </Segment>

      </div>
    } else {
      content = <EmptyView style={{ marginTop: 100 }} />;
    }
  }

  return content;
};

export default WindTurbinesGrid;
