import React from "react";
import logoImg from "../../../assets/images/logo@3x.png";
import darkLogoImg from "../../../assets/images/dark-theme-logo.png";
import { Image } from "semantic-ui-react";

const THEMES = {
  lightTheme: "light",
  darkTheme: "dark"
}

const Logo = (props) => {
  const style = {
    imageSize: {
      maxHeight: "100%",
      maxWidth: "10rem",
      margin: "auto"
    },
  };

  return <Image style={style.imageSize} src={props.theme === THEMES.lightTheme ? logoImg : darkLogoImg} {...props} />

};

Logo.propTypes = {};

Logo.defaultProps = {};

export default Logo;
