import React, { useState, memo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Modal, TextArea } from "semantic-ui-react";

import {
  ParametersTable,
  Button,
  Form,
  Container,
  ScreenSizes,
} from "components/shared";

const StyledContainer = styled((props) => <Container {...props} />)`
  &&& {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
  }

  ${ScreenSizes.lessThan("mobile")`
    text-align: center;
`}
`;

const ServiceLogs = ({ logs, enableAdd, addLog, addLogHeader }) => {
  const [addEntryVisible, setAddEntryVisible] = useState(false);
  const [newEntry, setNewEntry] = useState("");

  const [previewSourceMultiple, setPreviewSourceMultiple] = useState([]);
  const [base64EncodedImageMultiple, setBase64EncodedImageMultiple] = useState(
    []
  );

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setBase64EncodedImageMultiple([
        ...base64EncodedImageMultiple,
        reader.result,
      ]);
      setPreviewSourceMultiple([...previewSourceMultiple, reader.result]);
    };
  };

  const resetImageState = () => {
    setPreviewSourceMultiple([]);
    setBase64EncodedImageMultiple([]);
  };

  const onAddLog = () => {
    setAddEntryVisible(false);
    addLog(newEntry, base64EncodedImageMultiple);
    resetImageState();
  };

  const onClose = () => {
    resetImageState();
    setAddEntryVisible(false);
  };

  const deleteFile = () => {
    resetImageState();
  };

  const onNewEntryChange = (e, data) => {
    setNewEntry(data.value);
  };

  return (
    <>
      <StyledContainer>
        {enableAdd && (
          <Button onClick={() => setAddEntryVisible(true)}>
            Add service entry
          </Button>
        )}
      </StyledContainer>

      <ParametersTable
        key="actionLogs"
        paginated
        numberOfItemsPerPage={4}
        params={logs}
        columnNames={["Service Man", "Notes", "Date", "Image"]}
      />

      <Modal
        size={"small"}
        open={addEntryVisible}
        onClose={() => setAddEntryVisible(false)}
      >
        <Modal.Header>{addLogHeader}</Modal.Header>

        <Modal.Content>
          <Form>
            <TextArea
              value={newEntry}
              placeholder="Fill the details here"
              onChange={onNewEntryChange}
            />
            {base64EncodedImageMultiple.length < 5 && (
              <div>
                <label for="fileInput" class="custom-file-upload">
                  <i class="fa fa-cloud-upload"></i> Choose File
                </label>{" "}
                (max 5 images)
                <input
                  id="fileInput"
                  type="file"
                  accept="image/*"
                  name="image"
                  onChange={handleFileInputChange}
                />
              </div>
            )}
          </Form>

          {previewSourceMultiple.length > 0 &&
            previewSourceMultiple.map((ps) => (
              <img src={ps} alt="chosen" style={{ height: "100px" }} />
            ))}
        </Modal.Content>

        <Modal.Actions>
          {previewSourceMultiple.length > 0 && (
            <Button onClick={deleteFile} positive>
              Delete Images
            </Button>
          )}
          <Button onClick={onClose} positive>
            Close
          </Button>
          <Button
            onClick={onAddLog}
            negative
            style={{ backgroundcolor: "red" }}
          >
            Add
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

ServiceLogs.propTypes = {
  logs: PropTypes.arrayOf(PropTypes.shape({})),
  enableAdd: PropTypes.bool,
  addLog: PropTypes.func,
  addLogHeader: PropTypes.string,
};

ServiceLogs.defaultProps = {
  logs: [],
  enableAdd: false,
  addLog: () => {},
  addLogHeader: "Add service entry",
};

export default memo(ServiceLogs);
